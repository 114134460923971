<template>
  <fw-layout
    back-to="/"
    full
    :loading="initialLoading"
    :loading-title="`${$t('application')} ${procedureCode}`"
    :notfound="initialLoading === false && !application"
    :header-session="false"
  >
    <template v-if="view !== 'dashboard'" #header-nav>
      <div class="flex gap-4 items-center">
        <div class="truncate max-w-lg">
          <fw-heading size="lg">
            <span class="font-semibold text-gray-500">{{ procedure.prefix }}</span>
            <span class="font-bold">{{ procedure.code }}</span>
          </fw-heading>
        </div>
        <div class="flex-shrink-0">
          <fw-tag size="sm" :type="applicationStates[application.status].color" custom-class="px-3">
            {{ applicationStates[application.status].label[language] }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template #header-toolbar>
      <div class="flex gap-2 items-center">
        <div v-if="checks.canReopen">
          <fw-button
            ref="reopen-button"
            type="link"
            :disabled="savingData"
            :loading="savingData"
            class="mx-2"
            @click.native="reopenApplication"
          >
            {{ $t('reopenApplication.title') }}
          </fw-button>
        </div>
        <div v-if="showTimeCounter">
          <TimeCounter :remaining-seconds="remainingSeconds" />
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarApplication
        :checks="checks"
        :steps="steps"
        :view="view"
        :saving-data="savingData"
        :form-with-errors="formWithErrors"
        @submit-application="submitApplication"
        @cancel-application="cancelApplication"
        @go-to-view="goToView"
        @go-to-section="goToSection"
      />
    </template>

    <template v-if="procedure && application" #main-content>
      <PanelApplicationDashboard
        v-if="view === 'dashboard'"
        :saving-data.sync="savingData"
        :application.sync="application"
        :procedure="procedure"
        :checks="checks"
      />
      <PanelApplicationMetadata
        v-else-if="view === 'metadata'"
        ref="applicationScholarships"
        :saving-data.sync="savingData"
        :files-options="entry.options.files"
        :application.sync="application"
        :procedure="procedure"
        :checks="checks"
        :can-edit="canEdit"
        :can-submit="canSubmit"
        :can-open="canReopen"
        :steps.sync="steps"
        @save="saveData"
        @download-file="downloadFile"
        @remove-file="removeFile"
        @relate-files="relateUploadedFile"
        @form-with-errors="formWithErrors = $event"
        @add-list-entry="addListEntry('other_scholarships', $event)"
        @update-list-entry="updateListEntry('other_scholarships', $event)"
        @delete-list-entry="deleteListEntry('other_scholarships', $event)"
      />
    </template>

    <template #modals>
      <ModalDownloading :show.sync="isDownloadingFile" :percentage.sync="$store.state.isDownloading" />

      <fw-modal v-if="showReceipt" :active.sync="showReceipt" :can-cancel="false" size="xs">
        <template #default>
          <CheckmarkSuccess />
          <div class="text-center flex flex-col gap-3 px-5">
            <fw-heading size="h3">{{ $t('applicationSubmitted') }}</fw-heading>
            <div class="text-xs text-gray-500">{{ $t('confirmationEmailSent') }}</div>
          </div>
        </template>
        <template #footer>
          <fw-button type="black" expanded @click.native="closeShowReceipt">{{ $t('close') }}</fw-button>
        </template>
      </fw-modal>

      <fw-modal
        v-if="timerInterval"
        :active.sync="showTimeAlmostOverWarning"
        :title="$t('deadlineEnding')"
        title-align="center"
        :can-cancel="false"
        @close="ackTimeIsAlmostOver()"
      >
        <template #default>
          <div class="flex flex-col items-center text-center max-w-xs mx-auto">
            <div class="flex justify-center pb-3">
              <TimeCounter size="lg" :remaining-seconds="remainingSeconds"></TimeCounter>
            </div>
            <div class="flex flex-col gap-2 text-sm">
              <div class="font-semibold">
                {{ $t('deadline', { code: procedure.code }) }}
              </div>
              <div>{{ $t('ifNotSubmittedInTime') }}</div>
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button type="black" expanded @click.native="ackTimeIsAlmostOver()">
            {{ $t('continue') }}
          </fw-button>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ModalDownloading from '@/components/modals/ModalDownloading'
import SidebarApplication from '@/components/sidebars/SidebarApplication'
import PanelApplicationMetadata from '@/components/application/PanelApplicationMetadata'
import PanelApplicationDashboard from '@/components/application/PanelApplicationDashboard'
import CheckmarkSuccess from '@/fw-modules/fw-core-vue/ui/components/animation/CheckmarkSuccess'
import TimeCounter from '@/fw-modules/fw-core-vue/forms/components/text/TimeCounter'
import { APPLICATION_STATES } from '@/utils/index.js'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    TimeCounter,
    SidebarApplication,
    ModalDownloading,
    PanelApplicationMetadata,
    PanelApplicationDashboard,
    CheckmarkSuccess,
  },

  data() {
    return {
      loading: false,
      initialLoading: true,
      entry: {},
      steps: [],
      showReceipt: false,
      formWithErrors: false,
      applicationStates: APPLICATION_STATES['scholarship'],
      alertReadyToSubmitShown: false,
      remainingSeconds: 0,
      remainingSecondsToShowCounter: 3600, // 1 hour
      secondsToShowTimeAlmostOverWarning: 60 * 10,
      showTimeAlmostOverWarning: false,
      timeAlmostOverWarningShown: false,
      timerInterval: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    view() {
      return this.$route.params.view
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    procedure() {
      return this.entry.procedure
    },
    application() {
      return this.entry.application
    },
    procedureCode() {
      return this.$route.params.procedureKey
    },
    procedureKey() {
      return this.$route.params.procedureKey
    },
    applicationStatus() {
      return this.application && this.application.status ? this.$store.state.locales[this.application.status] : ''
    },
    admissionStatus() {
      return this.application ? this.application.admission_status : null
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
    savingData() {
      return this.$store.state.isSaving
    },
    showTimeCounter() {
      // Be careful!!! Submit will depend on this
      return (
        this.application.status === 'draft' &&
        this.timerInterval != null &&
        this.remainingSeconds < this.remainingSecondsToShowCounter
      )
    },

    // Checks
    checks() {
      return {
        canEdit: this.canEdit,
        canSubmit: this.canSubmit,
        canCancel: this.canCancel,
        canReopen: this.canReopen,
        canComplain: this.canComplain,
        isSubmitted: this.isSubmitted,
        isCanceled: this.isCanceled,
        isDraft: this.isDraft,
      }
    },
    isDraft() {
      return this.application && this.application.status == 'draft'
    },
    isSubmitted() {
      return this.application && this.application.status == 'submitted'
    },
    isCancelled() {
      return this.application && this.application.status == 'cancelled'
    },
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    isEntryLoaded() {
      return this.entry && this.entry.application && this.initialLoading == false
    },
    canCancel() {
      return (
        this.application &&
        this.procedure &&
        this.application.status === 'draft' &&
        this.entry.permissions.edit &&
        this.entry.permissions.change_status
      )
    },
    canEdit() {
      return this.entry && this.entry.permissions && this.entry.permissions.edit
    },
    canSubmit() {
      return (
        this.entry &&
        this.entry.permissions &&
        this.entry.permissions.submit &&
        this.entry.permissions.change_status &&
        this.remainingSeconds > 0
      )
    },
    canComplain() {
      return this.entry && this.entry.permissions && this.entry.permissions.can_complain
    },
    canReopen() {
      return (
        this.application &&
        this.procedure &&
        this.application.status != 'draft' &&
        this.procedure.status != 'closed' &&
        this.procedure.status != 'ended' &&
        this.entry.permissions.change_status
      )
    },
  },

  mounted() {
    this.getApplication()
  },

  beforeDestroy() {
    this.stopTime()
  },

  methods: {
    // Main data methods
    setApplicationData(application) {
      if (application) {
        // Defaults
        let defaultCountryPhone = {
          code: '+351',
          key: 'PT',
          title: 'Portugal',
        }
        let defaultCountry = {
          key: 'PT',
          title: 'Portugal',
        }

        // Set form data
        application['country'] =
          application['country'] && application['country']['key'] ? application['country'] : defaultCountry

        if (!application.email) {
          application.email = this.user.email
        }

        application.phone_country = application.phone_country.code ? application.phone_country : defaultCountryPhone
        if (application.phone_number) {
          application.phone_number = Number(application.phone_number)
        }

        application['identity_expire_date'] = application['identity_expire_date']
          ? Dates.build(application['identity_expire_date']).toDate()
          : null

        application['nif'] = application.nif ? Number(application.nif) : null

        if (!application.enrolled_course) {
          application.enrolled_course = {
            type: null,
            name: null,
            academic_year: {
              start: null,
              end: null,
            },
          }
        } else if (application.enrolled_course.academic_year) {
          application.enrolled_course.academic_year = {
            start: Number(application.enrolled_course.academic_year.split('/')[0]),
            end: Number(application.enrolled_course.academic_year.split('/')[1]),
          }
        } else {
          application.enrolled_course.academic_year = {
            start: null,
            end: null,
          }
        }
      }

      // Update application data
      this.entry.application = application
    },

    async getApplication() {
      this.loading = true
      let application

      // Register a new application or get an existing one
      if (this.$route.params.view == 'start') {
        this.entry = await this.api.startApplication(this.$route.params.procedureKey)
        application = this.entry.application
        // Update view
        this.goToView('metadata')
        this.$router.replace({ ...this.$router.currentRoute, params: { action: this.view } })
      }

      // Get application
      utils.tryAndCatch(
        this,
        async () => {
          this.entry = await this.api.getApplication(this.$route.params.procedureKey)
          console.log('getApplication ---------', this.entry)
          application = this.entry.application

          this.setApplicationData(application)

          // Update remaining time to submit application
          this.remainingSeconds = this.procedure.time_for_publish_end

          // Start clock
          if (this.remainingSeconds > 0) this.startTime()

          // Redirect to dashboard if application is submitted
          // Run this only once (on initial load)
          if (this.initialLoading && this.$route.params.view === 'view') {
            var redirectToView = 'metadata'
            if (this.application.status === 'submitted') {
              redirectToView = 'dashboard'
            }
            this.$router.replace({ ...this.$router.currentRoute, params: { view: redirectToView } })
          }
        },
        () => {
          this.loading = false
          setTimeout(() => {
            this.initialLoading = false
            // Log activity to keep track of user actions (just on first load)
            if (this.initialLoading === false) {
              this.logActivity('user-enter-application')
            }
          }, 500)
        }
      )
    },

    // Actions
    submitApplication() {
      if (!this.canSubmit) return
      this.$buefy.dialog.confirm({
        title: this.$t('submitApplication.title'),
        message: this.$t('submitApplication.message'),
        confirmText: this.$t('submit'),
        cancelText: this.$t('cancel'),
        onConfirm: () => {
          this.saveData('save')
          this.saveData('submit')
        },
      })
    },

    cancelApplication() {
      this.$buefy.dialog.confirm({
        type: 'is-dark',
        title: this.$t('cancelApplication.title'),
        message: this.$t('cancelApplication.message'),
        confirmText: this.$t('cancelApplication.confirm'),
        cancelText: this.$t('cancelApplication.cancel'),
        onConfirm: () => {
          this.saveData('cancel')
        },
      })
    },

    reopenApplication() {
      this.$buefy.dialog.confirm({
        title: this.$t('reopenApplication.title'),
        message: this.$t('reopenApplication.message'),
        confirmText: this.$t('reopenApplication.confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => {
          this.saveData('reopen')
          this.goToView('metadata')
        },
      })
    },

    // Save and update data
    setDirty(value = true) {
      this.$store.commit('setDirtyData', value)
    },

    async saveData(type = null, application = null) {
      if (!type) type = 'update'
      if (!application) application = this.entry.application

      console.log('Got applications: ', application)
      const tmpApplication = JSON.parse(JSON.stringify(application))

      this.$store.commit('setSavingData', true)

      console.log('Save applications: ', application)

      if (type === 'submit') {
        tmpApplication['status'] = 'submitted'
      } else if (type === 'cancel') {
        tmpApplication['status'] = 'cancelled'
      } else if (type === 'reopen') {
        tmpApplication['status'] = 'draft'
      }

      if (tmpApplication.enrolled_course.academic_year) {
        if (!tmpApplication.enrolled_course.academic_year.start) {
          tmpApplication.enrolled_course.academic_year.start = ''
        }
        if (!tmpApplication.enrolled_course.academic_year.end) {
          tmpApplication.enrolled_course.academic_year.end = ''
        }
        tmpApplication.enrolled_course.academic_year =
          tmpApplication.enrolled_course.academic_year.start + '/' + tmpApplication.enrolled_course.academic_year.end
      }

      // Parse VAT number (make sure it's null if empty)
      if (tmpApplication.nif === '') tmpApplication.nif = null

      // Parse dates
      tmpApplication['identity_expire_date'] = tmpApplication['identity_expire_date']
        ? Dates.toDateString(tmpApplication['identity_expire_date'])
        : null

      utils.tryAndCatch(
        this,
        async () => {
          let response = await this.api.updateApplication(tmpApplication)
          this.entry = response

          this.setApplicationData(response.application)

          this.$store.commit('setDirtyData', false)

          setTimeout(() => {
            this.$store.commit('setSavingData', false)
          }, 500)

          // Show receipt if submitted
          if (type === 'submit' && response.application.status == 'submitted') {
            setTimeout(() => {
              this.showReceipt = true
            }, 100)
            this.getApplication()
          }

          // User feedback
          else if (type === 'update') {
            // Add more info when we save data
            var msg = this.$t('dataSaved')
            if (this.formWithErrors) {
              msg = this.$t('dataSavedWithErrors')
            }

            this.$buefy.snackbar.open({
              message: msg,
              type: 'is-white',
              position: 'is-bottom-right',
              indefinite: false,
              duration: 2000,
              queue: false,
            })
            if (!this.formWithErrors && this.canSubmit && !this.alertReadyToSubmitShown) {
              setTimeout(() => {
                this.$buefy.snackbar.open({
                  message: this.$t('applicationIsReady'),
                  type: 'is-primary',
                  position: 'is-bottom-right',
                  indefinite: false,
                  duration: 4000,
                  queue: false,
                })
              }, 1750)
              this.alertReadyToSubmitShown = true
              this.logActivity('user-save-application-ready-to-submit')
            }
          }
        },
        () => {
          setTimeout(() => {
            this.$store.commit('setSavingData', false)
          }, 500)
        }
      )
    },

    saveChildApplication() {
      this.$refs.applicationScholarships.saveApplication()
    },

    // Entries
    async addListEntry(type, data) {
      console.log('addListEntry', data)

      // Parse dates
      if (data.start_date) data.start_date = Dates.buildCore(data.start_date).format('YYYY-MM-DD')
      if (data.end_date) data.end_date = Dates.buildCore(data.end_date).format('YYYY-MM-DD')

      try {
        let res = await this.api.addListEntry(this.procedureCode, type, data)
        this.entry.application.other_scholarships = res.items
        console.log('addListEntry result', res)

        this.$store.commit('setDirtyData', false)
        this.$store.commit('setSavingData', false)
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        this.$store.commit('setSavingData', false)
        this.$buefy.dialog.alert({
          title: this.$t('errorDefault.title'),
          message: `${this.$t('errorDefault.message', { errorKey: errorKey ? `(${errorKey})` : '' })} ${this.$t(
            'savingApplication'
          )}. ${this.$t('errorDefault.contactSupport')}`,
          type: 'is-danger',
        })
      }
    },

    async updateListEntry(type, data) {
      console.log('updateListEntry', type, data)
      if (!data.key) {
        return
      }

      // Parse dates
      if (data.start_date) data.start_date = Dates.buildCore(data.start_date).format('YYYY-MM-DD')
      if (data.end_date) data.end_date = Dates.buildCore(data.end_date).format('YYYY-MM-DD')

      try {
        let res = await this.api.updateListEntry(this.procedureCode, type, data)
        this.entry.application.other_scholarships = res.items
        console.log('updateListEntry result', res)

        this.$store.commit('setDirtyData', false)
        this.$store.commit('setSavingData', false)
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        this.$store.commit('setSavingData', false)
        this.$buefy.dialog.alert({
          title: this.$t('errorDefault.title'),
          message: `${this.$t('errorDefault.message', { errorKey: errorKey ? `(${errorKey})` : '' })} ${this.$t(
            'savingApplication'
          )}. ${this.$t('errorDefault.contactSupport')}`,
          type: 'is-danger',
        })
      }
    },

    async deleteListEntry(type, key) {
      console.log('deleteListEntry', type, key)
      if (!key) {
        return
      }

      try {
        let res = await this.api.deleteListEntry(this.procedureCode, type, key)
        this.entry.application.other_scholarships = res.items
        console.log('deleteListEntry result', res)

        this.$store.commit('setDirtyData', false)
        this.$store.commit('setSavingData', false)
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        this.$store.commit('setSavingData', false)
        this.$buefy.dialog.alert({
          title: this.$t('errorDefault.title'),
          message: `${this.$t('errorDefault.message', { errorKey: errorKey ? `(${errorKey})` : '' })} ${this.$t(
            'savingApplication'
          )}. ${this.$t('errorDefault.contactSupport')}`,
          type: 'is-danger',
        })
      }
    },

    // Navigation
    goToView(view) {
      this.$router.push({
        name: 'application',
        params: { key: this.procedureKey, view: view },
      })
    },

    goToSection(id) {
      let section = document.querySelector('#form_section_' + id)
      if (section) {
        let top = section.getBoundingClientRect().top
        document.querySelector('.form-wrapper').scroll({
          top: document.querySelector('.form-wrapper').scrollTop + top - 125,
          behavior: 'smooth', // 👈
        })
        console.log('scroll to section', id, top)
      }
    },

    closeShowReceipt() {
      this.showReceipt = false
      this.goToView('dashboard')
    },

    // Files
    async relateUploadedFile(fileType, files) {
      console.log('relateUploadedFile', fileType, files)
      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        try {
          const res = await this.api.relateFile(this.procedureCode, fileType, file.key)
          if (this.application.files[fileType]) {
            this.entry.application.files[fileType].push(res)
          } else {
            this.$set(this.entry.application.files, fileType, [res])
          }
          console.log('this.application.files', this.application.files)
        } catch (error) {
          const errorKey = utils.errors(error).getKey()
          console.log('Error KEY', errorKey)
          let title, message
          if (errorKey === 'MaxNumFiles') {
            title = this.$t('maxNumFiles.title')
            message = this.$t('maxNumFiles.message')
          } else {
            title = this.$t('errorDefault.title')
            message = `${this.$t('errorDefault.message', { errorKey: errorKey ? `(${errorKey})` : '' })} ${this.$t(
              'savingFile'
            )}. ${this.$t('errorDefault.contactSupport')}`
          }
          console.error('Failed to add file to application', error)
          this.$buefy.dialog.alert({
            title: title,
            message: message,
            type: 'is-danger',
          })
        }
      }
    },

    async downloadFile(file) {
      console.log('downloadFile', file)
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
      this.logActivity('download-file', { file: file.key })
    },

    async removeFile(file, fileType) {
      const fileKey = file.key
      try {
        const response = await this.api.deleteFile(fileKey)
        console.log('removeFile', file.key, response)
        this.entry.application.files[fileType] = this.entry.application.files[fileType].filter(el => el.key != fileKey)
      } catch (error) {
        console.error(error)
      }
    },

    // Deal with time
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },

    startTime() {
      let self = this
      this.timeTick()
      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)
    },

    timeTick() {
      this.remainingSeconds -= 1
      this.checkTimeWarnings()
      //console.log('time tick', this.remainingSeconds)
    },

    checkTimeWarnings() {
      // Show critical warning - time is almost over, dude!
      // Make sure we show this only when time is not over
      if (
        this.remainingSeconds > 0 &&
        this.remainingSeconds < this.secondsToShowTimeAlmostOverWarning &&
        this.application.status === 'draft' &&
        !this.timeAlmostOverWarningShown
      ) {
        this.showTimeAlmostOverWarning = true
        this.timeAlmostOverWarningShown = true

        this.logActivity('show-time-almost-over-warning')
      }

      // Check time is over
      if (this.remainingSeconds <= 0) {
        this.stopTime()
        console.log('Time is over!')

        this.logActivity('time-is-over-warning-auto-cancel')

        // Redirect to call page
        setTimeout(() => {
          this.$router.go()
        }, 150)
      }
    },

    ackTimeIsAlmostOver() {
      this.showTimeAlmostOverWarning = false
      // Log this activity
      this.logActivity('timeover-warning-ack')
      console.log('User ack warning of time almost over.')
    },

    // Log activity
    logActivity(action, metadata = {}, byUserInteraction = true) {
      this.$store.dispatch('saveActivityAction', {
        service: 'apply',
        metadata: metadata,
        action: action,
        elementType: 'application',
        elementID: this.application != null ? this.application.key : 'NULL',
        userInteraction: byUserInteraction,
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "personalData": "Dados pessoais",
    "cv": "Curriculum Vitae",
    "certificates": "Certificados de habilitações",
    "letterOfInterest": "Carta de motivação",
    "recommendationLetters": "Carta(s) de recomendação",
    "declarationOfHonor":  "Declaração de honra",
    "application": "Candidatura",
    "draft": "Rascunho",
    "jury": "Júri",
    "running": "Iniciado",
    "ended": "Audiência(s) de interessados",
    "closed": "Encerrado",
    "published": "Candidaturas",
    "submitted": "Submetida",
    "area_of": "Área disciplinar de",
    "subarea_of": "subárea de",
    "procedureAbout": "Sobre o procedimento",
    "complaints": "Audiência(s) de interessados",
    "sections": "Seções",
    "next": "Avançar",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "docsToSubmit": "Documentos a entregar",
    "close": "Fechar",
    "continue": "Continuar",
    "deadlineEnding": "O prazo está prestes a terminar!",
    "applicationSubmitted": "Candidatura submetida!",
    "confirmationEmailSent": "Foi enviado um email de confirmação.",
    "deadline": "Alertamos que o prazo limite para submissão de candidatura ao abrigo do procedimento {code} está prestes a terminar.",
    "ifNotSubmittedInTime": "Caso não submeta a sua candidatura, antes de terminar o prazo, a mesma será <b>cancelada</b>.",
    "errorDefault": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado {errorKey}",
      "contactSupport": "Por favor, contacte a nossa equipa de suporte."
    },
    "savingApplication": "a guardar a candidatura",
    "savingFile": "a guardar o ficheiro",
    "maxNumFiles": {
      "title": "Número máximo de ficheiros atingido",
      "message": "O número máximo de ficheiros foi atingido."
    },
    "cancelApplication": {
      "title": "Cancelar candidatura",
      "message": "Tem a certeza que pretende cancelar a sua candidatura? Até ao final do prazo de candidatura, poderá reabrir e continuar o processo.",
      "confirm": "Sim, cancelar",
      "cancel": "Não cancelar"
    },
    "reopenApplication": {
      "title": "Reabrir candidatura",
      "message": "Ao reabrir a candidatura, a mesma voltará ao estado de rascunho e só será considerada caso seja submetida novamente.",
      "confirm": "Reabrir"
    },
    "submitApplication": {
      "title": "Tem a certeza que deseja submeter a sua candidatura?",
      "message": "<p>Após submissão e até à data de fim de prazo de candidaturas deste procedimento, poderá reabrir e editar a informação submetida.</p><p class='text-sm mt-1 text-gray-500'>Tenha atenção que ao reabrir a candidatura, a mesma deverá submetida novamente para que seja considerada.</p>"
    },
    "dataSaved": "Os dados foram guardados.",
    "dataSavedWithErrors": "Os dados foram guardados mas existem erros no formulário.",
    "applicationIsReady": "A sua candidatura está pronta a ser submetida! 🎉"
  },
  "en": {
    "personalData": "Personal details",
    "cv": "Curriculum Vitae",
    "certificates": "Qualification certificates",
    "letterOfInterest": "Motivation letter",
    "recommendationLetters": "Recommendation Letters",
    "declarationOfHonor":  "Declaration of Honor",
    "application": "Application",
    "draft": "Draft",
    "jury": "Jury",
    "running": "Running",
    "ended": "Stakeholder Hearing",
    "closed": "Ended",
    "published": "Published",
    "submitted": "Submitted",
    "area_of": "Subject area of",
    "subarea_of": "subarea of",
    "procedureAbout": "About the procedure",
    "complaints": "Stakeholder Hearing",
    "sections": "Sections",
    "next": "Next",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "close": "Close",
    "continue": "Continue",
    "deadlineEnding": "The deadline is about to expire!",
    "applicationSubmitted": "Application submitted!",
    "confirmationEmailSent": "A confirmation email has been sent.",
    "deadline": "Please note that the deadline for submitting an application under the {code} procedure is approaching.",
    "ifNotSubmittedInTime": "If you do not submit your application, before the deadline, it will be <b>cancelled</b>.",
    "docsToSubmit": "Documents to submit",
    "errorDefault": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred {errorKey}",
      "contactSupport": "Please contact our support team."
    },
    "savingApplication": "saving the application",
    "savingFile": "while saving the file",
    "maxNumFiles": {
      "title": "Maximum number of files reached",
      "message": "The maximum number of files has been reached."
    },
    "cancelApplication": {
      "title": "Cancel application",
      "message": "Are you sure you want to cancel the submission of your application? You'll be able to reopen and finalize this application until the deadline.",
      "confirm": "Sure, cancel",
      "cancel": "Do not cancel"
    },
    "reopenApplication": {
      "title": "Reopen application",
      "message": "By reopening your application, its status will be changed to draft and the application must be submitted to become valid.",
      "confirm": "Reopen"
    },
    "submitApplication": {
      "title": "Are you sure you want to submit your application?",
      "message": "<p>After submission and until the application deadline date of this procedure, you can reopen and edit the submitted information.</p><p class='text-sm mt-1 text-gray-500'>Please note that when re-opening the application, it must be resubmitted to be considered.</p>"
    },
    "dataSaved": "The data was saved.",
    "dataSavedWithErrors": "The data has been saved but there are errors in the form.",
    "applicationIsReady": "Your application is ready to be submitted! 🎉"
  }
}
</i18n>
