<template>
  <ExpandList
    :class="{ error: $v.entryTmpData.$error }"
    :add-title="$t('addScholarship')"
    :empty="previousScholarships.length === 0"
    :show-add-items="canEdit && editingEntry !== -2"
    @add-element="addEntry"
  >
    <template #list>
      <div v-for="(entry, m) in previousScholarships" :key="'entry_' + m">
        <div class="p-5 border-b border-gray-100 flex">
          <div v-if="editingEntry === m" class="w-full">
            <div>
              <fw-label>{{ $t('scholarshipType') }}</fw-label>
              <b-select
                v-model="$v.entryTmpData.type.$model"
                class="fw-select w-full"
                :disabled="!canEdit"
                :placeholder="$t('scholarshipType')"
              >
                <option value="research_initiation">{{ $t('type.research_initiation') }}</option>
                <option value="research">{{ $t('type.research') }}</option>
                <option value="post_doctoral">{{ $t('type.post_doctoral') }}</option>
                <option value="other">{{ $t('type.other') }}</option>
              </b-select>
              <fw-tip v-if="$v.entryTmpData.type.$error" error>
                {{ $t('errors.required.type') }}
              </fw-tip>
            </div>

            <div class="mt-5">
              <fw-label>{{ $t('startDate') }}</fw-label>
              <b-datepicker
                v-model="$v.entryTmpData.start_date.$model"
                :disabled="!canEdit"
                locale="pt-PT"
                :placeholder="$t('startDate')"
              >
              </b-datepicker>
              <fw-tip v-if="$v.entryTmpData.start_date.$error" error>
                {{ $t('errors.invalid.date') }}
              </fw-tip>
            </div>

            <div class="mt-5">
              <fw-label>{{ $t('endDate') }}</fw-label>
              <b-datepicker
                v-model="$v.entryTmpData.end_date.$model"
                :disabled="!canEdit"
                locale="pt-PT"
                :placeholder="$t('endDate')"
              >
              </b-datepicker>
              <fw-tip v-if="$v.entryTmpData.end_date.$error" error>
                {{ $t('errors.invalid.date') }}
              </fw-tip>
            </div>

            <div class="mt-5">
              <fw-label>{{ $t('entity') }}</fw-label>
              <TextInput
                v-model="$v.entryTmpData.entity.$model"
                :disabled="!canEdit"
                :minlength="3"
                :maxlength="255"
                :placeholder="$t('entity')"
                :class="{
                  error: $v.entryTmpData.entity,
                }"
              ></TextInput>
              <fw-tip v-if="$v.entryTmpData.entity.$error" error>
                {{ $t('errors.required.entity') }}
              </fw-tip>
            </div>

            <div class="mt-5">
              <fw-label>{{ $t('description') }}</fw-label>
              <b-input v-model="$v.entryTmpData.description.$model" type="textarea" :disabled="!canEdit"></b-input>
              <fw-tip v-if="$v.entryTmpData.description.$error" error>
                <span v-if="!$v.entryTmpData.description.required">{{ $t('errors.required.description') }}</span>
                <span v-else>{{ $t('errors.invalid.description') }}</span>
              </fw-tip>
            </div>
            <div class="flex-1 flex justify-end items-center mt-4 gap-4">
              <fw-button type="light" @click.native="closeEntry()">{{ $t('cancel') }}</fw-button>
              <fw-button type="primary" @click.native="saveEntry()">{{ $t('save') }}</fw-button>
            </div>
          </div>
          <div v-else class="w-full flex flex-col gap-1">
            <div class="flex-1">
              <fw-heading size="h4">{{ $t(`type.${entry.type}`) }} - {{ entry.entity }}</fw-heading>
              <div v-if="entry.description.length > 0" class="text-gray-500 text-xs font-medium mt-1 flex gap-0.5">
                {{ entry.description }}
              </div>
            </div>

            <div v-if="canEdit" class="flex justify-end items-center flex-shrink-0">
              <fw-button
                type="simple"
                size="sm"
                class="flex items-center gap-2 font-semibold opacity-50 flex-shrink-0"
                :label="$t('edit')"
                @click.native="editEntry(m)"
              >
                <fw-icon-edit class="w-5 h-5" /> {{ $t('edit') }}
              </fw-button>
              <fw-button
                type="simple"
                size="sm"
                class="flex items-center gap-2 font-semibold opacity-50 flex-shrink-0"
                :label="$t('delete')"
                @click.native="deleteEntry(m)"
              >
                <fw-icon-trash class="w-5 h-5" /> {{ $t('delete') }}
              </fw-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editingEntry === -2" class="w-full px-5 pb-5 pt-3">
        <div>
          <fw-label>{{ $t('scholarshipType') }}</fw-label>
          <b-select
            v-model="$v.entryTmpData.type.$model"
            class="fw-select w-full"
            :disabled="!canEdit"
            :placeholder="$t('scholarshipType')"
          >
            <option value="research_initiation">{{ $t('type.research_initiation') }}</option>
            <option value="research">{{ $t('type.research') }}</option>
            <option value="post_doctoral">{{ $t('type.post_doctoral') }}</option>
            <option value="other">{{ $t('type.other') }}</option>
          </b-select>
          <fw-tip v-if="$v.entryTmpData.type.$error" error>
            {{ $t('errors.required.type') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('startDate') }}</fw-label>
          <b-datepicker
            v-model="$v.entryTmpData.start_date.$model"
            :disabled="!canEdit"
            locale="pt-PT"
            :placeholder="$t('startDate')"
          >
          </b-datepicker>
          <fw-tip v-if="$v.entryTmpData.start_date.$error" error>
            {{ $t('errors.invalid.date') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('endDate') }}</fw-label>
          <b-datepicker
            v-model="$v.entryTmpData.end_date.$model"
            :disabled="!canEdit"
            locale="pt-PT"
            :placeholder="$t('startDate')"
          >
          </b-datepicker>
          <fw-tip v-if="$v.entryTmpData.end_date.$error" error>
            {{ $t('errors.invalid.date') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('entity') }}</fw-label>
          <TextInput
            v-model="$v.entryTmpData.entity.$model"
            :disabled="!canEdit"
            :minlength="3"
            :maxlength="255"
            :placeholder="$t('entity')"
            :class="{
              error: $v.entryTmpData.entity,
            }"
          ></TextInput>
          <fw-tip v-if="$v.entryTmpData.entity.$error" error>
            {{ $t('errors.required.entity') }}
          </fw-tip>
        </div>

        <div class="mt-5">
          <fw-label>{{ $t('description') }}</fw-label>
          <b-input v-model="$v.entryTmpData.description.$model" type="textarea" :disabled="!canEdit"></b-input>
          <fw-tip v-if="$v.entryTmpData.description.$error" error>
            <span v-if="!$v.entryTmpData.description.required">{{ $t('errors.required.description') }}</span>
            <span v-else>{{ $t('errors.invalid.description') }}</span>
          </fw-tip>
        </div>

        <div class="flex-1 flex justify-end items-center mt-4 gap-4">
          <fw-button type="light" @click.native="closeEntry()">{{ $t('cancel') }}</fw-button>
          <fw-button type="primary" @click.native="saveEntry(true)">{{ $t('save') }}</fw-button>
        </div>
      </div>
    </template>
  </ExpandList>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import { required, maxLength, minLength, minValue } from 'vuelidate/lib/validators'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    TextInput,
    ExpandList,
  },
  props: {
    disableAutowrite: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    previousScholarships: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  validations() {
    return {
      entryTmpData: {
        type: { required },
        start_date: { required },
        end_date: { required, min: minValue(this.entryTmpData.start_date) },
        entity: { required, min: minLength(3), max: maxLength(255) },
        description: { required, min: minLength(3) },
      },
    }
  },

  data() {
    return {
      editingEntry: -1,
      entryTmpData: {},
      maxDate: new Date(),
    }
  },

  methods: {
    addEntry() {
      this.$v.$reset()
      this.entryTmpData = {
        type: null,
        start_date: null,
        end_date: null,
        entity: null,
        description: null,
      }
      this.editingEntry = -2
    },
    editEntry(pos) {
      this.$v.$reset()
      this.entryTmpData = JSON.parse(JSON.stringify(this.previousScholarships[pos]))
      this.entryTmpData.start_date = Dates.buildCore(this.entryTmpData.start_date).toDate()
      this.entryTmpData.end_date = Dates.buildCore(this.entryTmpData.end_date).toDate()
      this.editingEntry = pos
    },
    saveEntry(isNew = false) {
      this.$v.entryTmpData.$touch()

      if (!this.$v.entryTmpData.$invalid) {
        if (isNew) {
          this.$emit('add', this.entryTmpData)
        } else {
          this.$emit('save', this.entryTmpData)
        }

        this.closeEntry()
      }
    },
    deleteEntry(pos) {
      this.$emit('delete', this.previousScholarships[pos].key)
    },
    closeEntry() {
      this.editingEntry = -1
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "addScholarship": "Adicionar bolsa recebida",
    "scholarshipType": "Tipo de bolsa",
    "entity": "Entidade financiadora",
    "description": "Descrição sucinta da bolsa",
    "startDate": "Data de inicio",
    "endDate": "Data de fim",
    "edit": "Editar",
    "delete": "Eliminar",
    "save": "Guardar",
    "cancel": "Cancelar",
    "type": {
      "research_initiation": "Bolsa de iniciação à investigação",
      "research": "Bolsa de investigação",
      "post_doctoral": "Bolsa de investigação pós-doutoral",
      "other": "Outra"
    },
    "errors": {
      "required": {
        "type": "Insira o tipo de bolsa",
        "description": "Insira uma descrição sucinta da bolsa",
        "entity": "Insira a entidade financiadora"
      },
      "invalid": {
        "date": "Data inválida",
        "description": "Descrição da bolsa inválida"
      }
    }
  },
  "en": {
    "addScholarship": "Add scholarship received",
    "scholarshipType": "Scholarship type",
    "entity": "Funding entity",
    "description": "Brief description of the scholarship",
    "startDate": "Start date",
    "endDate": "End date",
    "edit": "Edit",
    "delete": "Delete",
    "save": "Save",
    "cancel": "Cancel",
    "type": {
      "research_initiation": "Research Initiation Scholarship",
      "research": "Research grant",
      "post_doctoral": "Post-doctoral research fellowship",
      "other": "Other"
    },
    "errors": {
      "required": {
        "type": "Add the scholarship type",
        "description": "Add a scholarship description",
        "entity": "Add the funding entity"
      },
      "invalid": {
        "date": "Invalid date",
        "description": "Invalid scholarship description"
      }
    }
  }
}
</i18n>
