var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.steps.length > 0)?_c('div',{ref:"vertical-steps",staticClass:"relative"},[(_vm.title)?_c('div',{staticClass:"text-gray-400 font-semibold text-sm m-2"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"hidden timeline-vertical absolute top-4 bottom-4 rounded-xl bg-gray-200",staticStyle:{"left":"16px","width":"2px"}}),_c('div',{staticClass:"hidden timeline-vertical absolute top-4 rounded-xl bg-primary bg-opacity-50",staticStyle:{"left":"16px","width":"2px"},style:({ height: _vm.timeline_done_height + 'px' })}),_vm._l((_vm.steps),function(step){return _c('button',{key:'step_' + step.value + '_' + step.status,ref:step.status === 'finished' ? 'stepdone' : 'notdone',refInFor:true,staticClass:"group inline-flex gap-3 rounded px-2 py-1 w-full transition-colors duration-150 items-center",class:[
      {
        stepdone: step.status === 'finished',
        'text-primary font-medium': step.value === _vm.currentStep,
        'font-medium text-gray-600': step.value !== _vm.currentStep,
        'cursor-not-allowed': step.status === 'locked',
        'hover:text-primary': step.status !== 'locked',
      },
      `text-${_vm.size}`,
    ],on:{"click":function($event){step.status !== 'locked' ? _vm.clicked(step.value) : null}}},[(step.status === 'finished')?_c('div',{staticClass:"flex-shrink-0 bg-white rounded-full flex items-center justify-center",staticStyle:{"width":"21px","height":"21px"}},[_c('fw-icon-check',{staticClass:"text-primary w-4 h-4"})],1):(step.status === 'next')?_c('div',{staticClass:"flex-shrink-0 rounded-full flex items-center p-0.5 justify-center",staticStyle:{"width":"21px","height":"21px"}},[_c('div',{staticClass:"bg-gray-200 rounded-full inline-block",staticStyle:{"width":"12px","height":"12px"}})]):(step.status === 'locked')?_c('div',{staticClass:"flex-shrink-0 bg-white rounded-full text-gray-400 flex items-center p-0.5 justify-center",staticStyle:{"width":"21px","height":"21px","margin-left":"5px","line-height":"12px","padding-left":"1px","padding-top":"1px"}},[_c('svg',{staticClass:"fill-current",staticStyle:{"width":"11px","height":"10px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zm-2 0V9A5 5 0 0 0 7 9v1h10zm-6 4v4h2v-4h-2z"}})])]):(step.status === 'letter')?_c('div',{staticClass:"flex-shrink-0 bg-white rounded-full flex items-center p-0.5 justify-center text-primary",class:[`text-${_vm.size}`],staticStyle:{"width":"21px","height":"21px","margin-left":"5px","line-height":"12px","padding-left":"1px","padding-top":"1px"}},[_vm._v(" "+_vm._s(step.letter)+" ")]):_c('div',{staticClass:"flex-shrink-0 mx-1 bg-gray-200 rounded-full",staticStyle:{"width":"12px","height":"12px","margin-left":"5px","margin-top":"1px"}}),_c('div',{staticClass:"group-opacity-100 text-left pr-1",class:{
        'opacity-50 group-opacity-50': step.status === 'locked',
        truncate: _vm.truncateText,
      }},[_vm._v(" "+_vm._s(step.text)+" "),(step.meeting_datetime !== null)?_c('fw-label',[_vm._v(_vm._s(_vm._f("formatDayWithTime")(step.meeting_datetime)))]):_vm._e()],1)])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }