<template>
  <SidebarBase>
    <ButtonSidebar
      v-if="checks.isSubmitted"
      :active="view == 'dashboard'"
      label="Dashboard"
      icon="dashboard"
      @click.native="$emit('go-to-view', 'dashboard')"
    />
    <div v-if="checks.canEdit" class="mb-4 mx-2 text-center font-semibold text-sm">
      <div class="flex flex-col gap-2">
        <fw-button
          :type="readyToSubmit ? 'primary' : 'light'"
          size="md"
          expanded
          :disabled="savingData || !checks.canSubmit || formWithErrors || !readyToSubmit"
          @click.native="$emit('submit-application')"
          >{{ $t('submit') }}</fw-button
        >
        <fw-button
          v-if="checks.canEdit"
          :type="readyToSubmit ? 'xlight' : 'light'"
          expanded
          :disabled="savingData"
          :loading="savingData"
          @click.native="$emit('cancel-application')"
          >{{ $t('cancel') }}</fw-button
        >
      </div>
      <div class="opacity-70">
        <fw-label size="xs">{{ $t('progressAt') }} {{ progress }}%</fw-label>
        <ProgressIcon type="regular" bar-color="gray-500" :progress="progress" class="mx-4" />
      </div>
    </div>
    <ButtonSidebar
      v-if="!checks.isDraft"
      :active="view == 'metadata'"
      :label="$t('application')"
      icon="exam"
      @click.native="$emit('go-to-view', 'metadata')"
    />
    <VerticalSteps
      v-if="view == 'metadata'"
      :title="$t('sections')"
      :steps="steps"
      :current-step="section"
      @clicked="$emit('go-to-section', $event)"
    />
    <ButtonSidebar
      v-if="checks.isAccepted"
      :active="view == 'receipts'"
      :label="$t('receipts')"
      icon="file-pdf"
      @click.native="$emit('go-to-view', 'receipts')"
    />
  </SidebarBase>
</template>

<script>
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    VerticalSteps,
    ButtonSidebar,
    SidebarBase,
    ProgressIcon,
  },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    checks: {
      type: Object,
      default: () => {},
    },
    view: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    formWithErrors: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    procedureKey() {
      return this.$route.params.key
    },
    language() {
      return this.$store.state.language
    },
    progress() {
      return this.steps.length
        ? Math.round((this.steps.filter(step => step.status == 'finished').length / this.steps.length) * 100)
        : 0
    },
    readyToSubmit() {
      return this.progress === 100 && this.checks.isDraft && !this.checks.isCancelled && this.checks.canSubmit
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "home": "Início",
    "applications": "Candidaturas",
    "application": "Candidatura",
    "call": "Procedimento",
    "sections": "Seções",
    "receipts": "Recibos",
    "submit": "Submeter",
    "cancel": "Cancelar",
    "progressAt": "Preenchimento a",
    "status": {
      "draft": "Rascunho",
      "submitted": "Submetida",
      "canceled": "Cancelada"
    }
  },
  "en": {
    "home": "Home",
    "applications": "Applications",
    "application": "Application",
    "call": "call",
    "sections": "Sections",
    "receipts": "Receipts",
    "submit": "Submit",
    "cancel": "Cancel",
    "progressAt": "Progress at",
    "status": {
      "draft": "Rascunho",
      "submitted": "Submetida",
      "canceled": "Cancelada"
    }
  }
}
</i18n>
