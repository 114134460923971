<template>
  <div>
    <b-modal :active.sync="isActive" :width="500" scroll="keep" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card has-margin" style="width: auto">
        <section class="modal-card-body">
          <h2 class="lead active">{{ $t('downloadingFile') }}...</h2>
          <div class="has-margin-top">
            <b-progress size="is-tiny" type="is-primary" :value="parseInt(percentageActive)"></b-progress>
            <div>{{ percentageActive }}%</div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    percentage: Number,
  },
  data() {
    return {
      isActive: this.show || false,
      percentageActive: 0,
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
    percentage(value) {
      this.percentageActive = value
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "downloadingFile": "A descarregar o ficheiro"
  },
  "en": {
    "downloadingFile": "Fownloading file"
  }
}
</i18n>
