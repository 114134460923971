export const APPLICATION_STATES = {
  scholarship: {
    draft: {
      label: { pt: 'Rascunho', en: 'Draft' },
      color: 'orange',
    },
    submitted: {
      label: { pt: 'Submetida', en: 'Submitted' },
      color: 'primary',
    },
    running: {
      label: { pt: 'Em análise', en: 'Under review' },
      color: 'primary',
    },
    cancelled: {
      label: { pt: 'Cancelado', en: 'Cancelled' },
      color: 'medium',
    },
    closed: {
      label: { pt: 'Fechado', en: 'Ended' },
      color: 'medium',
    },
  },
}
