<template>
  <div>
    <fw-panel-info v-if="deadlineExceeded" clean>
      {{ $t('complaintsMaxDateEnd') }}
    </fw-panel-info>

    <div v-if="canComplain && !candidateComplaints.length" class="flex flex-col gap-5 p-3">
      <div>
        <fw-heading size="h3" muted>{{ $t('submitStatement') }}</fw-heading>
        <div class="text-sm font-medium text-gray-500 mt-0.5">
          {{ $t('availableUntil') }} {{ deadlineToComplaint | formatDate }}.
        </div>
      </div>
      <div>
        <fw-label>{{ $t('message') }}</fw-label>
        <div class="html-editor rounded-lg">
          <editor-content :editor="complaintEditor" />
        </div>
        <fw-tip v-if="$v.newMessage.description.$error" error>
          {{ $t('requiredDescription') }}
        </fw-tip>
      </div>
      <div>
        <fw-label>{{ $t('attachedFiles') }}</fw-label>
        <div v-if="newMessage.files && newMessage.files.length > 0" class="files mb-1.5">
          <RecordFileEntry
            v-for="file in newMessage.files"
            :key="file.key"
            :can-edit="false"
            :can-remove="true"
            :allow-classified="false"
            :file="file"
            @remove="removeFile(file, $event)"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          :label="$t('uploadFiles')"
          :is-docked="true"
          :layout="'minimal'"
          :reference-id="'uploader_update'"
          :allowed="'all'"
          :clear-after="true"
          input-id="upload_input"
          :files.sync="filesToUpload"
          :size="0"
          :new-file-context="{}"
          :file-type="'file'"
          :file-code="''"
          :uploader-class="'w-full rounded'"
          @upload="uploaded($event)"
        />
      </div>
      <div class="flex justify-end">
        <fw-button type="primary" wider @click.native="addMessage()">{{ $t('send') }} </fw-button>
      </div>
    </div>

    <div v-if="candidateComplaints.length" class="flex flex-col">
      <div class="rounded-xl p-3 flex items-start gap-3">
        <div
          v-if="candidateComplaints[0].user && candidateComplaints[0].user.key === application.key"
          class="flex-shrink-0"
        >
          <fw-avatar :user="candidateComplaints[0].user" />
        </div>
        <div class="flex-1 flex flex-col gap-0.5">
          <div class="flex gap-3 text-gray-500 items-center h-8">
            <div class="font-semibold flex-1">
              <v-clamp autoresize :max-lines="1">
                {{ candidateComplaints[0].user.full_name }}
              </v-clamp>
            </div>
            <div class="text-sm">{{ candidateComplaints[0].created_date | formatDateTime }}</div>
          </div>
          <div class="mb-2">
            <div class="message-content" v-html="candidateComplaints[0].description"></div>
          </div>
          <div v-if="candidateComplaints[0].files && candidateComplaints[0].files.length > 0">
            <fw-label>Ficheiros</fw-label>
            <div>
              <RecordFileEntry
                v-for="file in candidateComplaints[0].files"
                :key="file.key"
                paddingless
                class="mb-2"
                :allow-classified="false"
                :file="file"
                :can-edit="false"
                :can-remove="false"
                @download="downloadFile(file)"
              />
            </div>
          </div>
        </div>
      </div>

      <template v-if="candidateComplaints[0].messages && candidateComplaints[0].messages.length">
        <div
          v-for="message in candidateComplaints[0].messages"
          :key="message.key"
          class="rounded-xl p-3 flex items-start gap-3"
        >
          <div v-if="message.user && message.user.key === application.key" class="flex-shrink-0">
            <fw-avatar :user="message.user" />
          </div>
          <div class="flex-1 flex flex-col gap-0.5">
            <div class="flex gap-3 text-gray-500 items-center h-8">
              <div class="font-semibold flex-1">
                <v-clamp autoresize :max-lines="1">
                  {{ message.user.full_name }}
                </v-clamp>
              </div>
              <div class="text-sm">{{ message.created_date | formatDateTime }}</div>
            </div>
            <div class="mb-2">
              <div class="message-content" v-html="message.description"></div>
            </div>
            <div v-if="message.files && message.files.length > 0">
              <fw-label>Ficheiros</fw-label>
              <div>
                <RecordFileEntry
                  v-for="file in message.files"
                  :key="file.key"
                  paddingless
                  class="mb-2"
                  :allow-classified="false"
                  :file="file"
                  :can-edit="false"
                  :can-remove="false"
                  @download="downloadFile(file)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <fw-panel-info debug label="Data (raw)" class="my-5">
        <json-viewer
          :value="{ complaint: candidateComplaints[0], messages: candidateComplaints[0].messages }"
        ></json-viewer>
      </fw-panel-info>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    EditorContent,
    Uploader,
    RecordFileEntry,
  },
  props: {
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedureCode: {
      type: String,
      require: true,
    },
    deadlineExceeded: {
      type: Boolean,
      default: false,
    },
    deadlineToComplaint: {
      type: String,
      default: null,
    },
    canComplain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filesToUpload: [],
      candidateComplaints: [],
      newMessage: {
        description: '',
        files: [],
      },
      // Editor respostas a reclamações
      complaintEditor: new Editor({
        editable: true,
        extensions: [
          StarterKit,
          Placeholder.configure({
            emptyNodeClass: 'placeholder-editor',
            placeholder: this.$t('candidatePlaceholder'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ editor }) => {
          this.newMessage.description = editor.getHTML()
        },
      }),
    }
  },

  validations: {
    newMessage: {
      description: { required, min: minLength(2) },
    },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getComplaintMessages()
  },

  beforeDestroy() {
    this.complaintEditor.destroy()
  },

  methods: {
    async getComplaintMessages() {
      utils.tryAndCatch(this, async () => {
        this.candidateComplaints = await this.api.getCurrentUserComplaints(this.procedureCode)
      })
    },

    addMessage() {
      this.$v.newMessage.$touch()
      if (this.$v.newMessage.$invalid) return

      this.$buefy.dialog.confirm({
        message: !this.candidateComplaints.length ? this.$t('confirmSendComplaint') : this.$t('confirmSendMessage'),
        confirmText: this.$t('send'),
        cancelText: this.$t('cancel'),
        type: 'is-primary',
        onConfirm: () => {
          this.doAddMessage()
        },
      })
    },

    async doAddMessage() {
      utils.tryAndCatch(this, async () => {
        const complaint = await this.api.createComplaint(this.procedureCode, this.newMessage.description)
        complaint['files'] = this.newMessage.files

        // Associate files to complaint
        // We should be doing this one time only
        if (complaint.files.length > 0) {
          for (let file of complaint.files) {
            await this.api.relateComplaintFile(complaint.key, file.key)
          }
        }

        // Append complaint to list
        this.candidateComplaints.push(complaint)

        // Clear message
        this.newMessage = {
          description: '',
          files: [],
        }
        this.complaintEditor.commands.setContent('')
      })
    },

    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.newMessage.files.splice(f, 1)
        },
      })
    },

    async uploaded(files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.newMessage['files'] = this.newMessage['files'].concat(newfiles)
    },
  },
}
</script>

<style lang="postcss">
.ProseMirror p.is-editor-empty:first-child:before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.file-uploads {
  @apply w-full;
}
</style>

<i18n>
{
  "pt": {
    "title": "Audiência de interessados",
    "candidatePlaceholder": "Escreva aqui...",
    "placeholder": "Escreva a sua resposta aqui...",
    "complaintsMaxDateEnd": "O prazo para apresentação de pronúncia já foi sido ultrapassado.",
    "candidate": "Candidato",
    "noComplaints": "Sem audiência de interessados",
    "attachedFiles": "Ficheiros anexos",
    "uploadFiles": "Carregar ficheiros",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "sendMessage": "Enviar mensagem",
    "confirmSendMessage": "Tem a certeza que deseja enviar a mensagem?",
    "sendComplaint": "Apresentar pronúncia",
    "confirmSendComplaint": "Tem a certeza que deseja enviar esta mensagem e apresentar a sua pronúncia a este procedimento?",
    "sendComplaintReply": "Responder",
    "confirmSendComplaintReply": "Tem a certeza que deseja enviar a mensagem?",
    "send": "Enviar",
    "cancel": "Cancelar",
    "submitStatement": "Apresentar pronúnica",
    "message": "Mensagem",
    "availableUntil": "Disponível até",
    "requiredDescription": "Insira a mensagem"
  },
  "en":{
    "title": "Stakeholder hearing",
    "candidatePlaceholder": "Write your text here...",
    "placeholder": "Write your answer here...",
    "complaintsMaxDateEnd": "The date for submit a statements to this procedure has ended.",
    "candidate": "Candidate",
    "noComplaints": "No statements",
    "attachedFiles": "Attached files",
    "uploadFiles": "Upload files",
    "send": "Send",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "errorOccurred": "An error occurred while sending the message.",
    "sendMessage": "Send message",
    "confirmSendMessage": "Are you sure you want to send this message?",
    "sendComplaint": "Submit statment",
    "confirmSendComplaint": "Are you sure you want to submit your statment for this procedure?",
    "sendComplaintReply": "Reply",
    "confirmSendComplaintReply": "Are you sure you want to send this message?",
    "cancel": "Cancel",
    "submitStatement": "Submit statement",
    "message": "Mensage",
    "availableUntil": "Available until",
    "requiredDescription": "Message is required"
  }
}
</i18n>
