<template>
  <div class="relative overflow-y-auto h-full px-10 py-5">
    <div class="mx-auto max-w-screen-lg">
      <div class="flex gap-10">
        <div class="flex-1">
          <fw-heading size="h2" muted>{{ $t('yourApplication') }}</fw-heading>
          <fw-heading size="h1">
            <span class="font-semibold text-gray-500">{{ procedure.prefix }}</span>
            <span class="font-bold">{{ procedure.code }}</span>
          </fw-heading>
          <div class="flex gap-10 my-5 font-medium text-gray-500">
            <div>
              <fw-label marginless>{{ $t('key') }}</fw-label>
              <div>{{ application.candidate_key }}</div>
            </div>
            <div>
              <fw-label marginless>{{ $t('creationDate') }}</fw-label>
              <div>{{ application.created_date | formatDateTime }}</div>
            </div>
            <div v-if="application.submitted_date">
              <fw-label marginless>{{ $t('submittedDate') }}</fw-label>
              <div class="flex items-center gap-1">
                <span>{{ application.submitted_date | formatDateTime }}</span>
                <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
              </div>
            </div>
          </div>
          <a
            :href="`/procedure/${procedureCode}`"
            target="_blank"
            class="text-primary rounded-lg font-bold transition-colors duration-150 border border-transparent focus:outline-none hover:opacity-80 text-sm"
          >
            {{ $t('seeProcedureDetails') }}
          </a>
        </div>
        <div class="w-64">
          <div>
            <fw-label>{{ $t('applicationStatus') }}</fw-label>
            <div>
              <fw-tag expanded size="xl" :type="applicationStates[application.status].color">{{
                applicationStates[application.status].label[language]
              }}</fw-tag>
            </div>
          </div>
          <div v-if="application.admission_status" class="mt-3">
            <fw-label marginless>{{ $t('decision') }}</fw-label>
            <div class="text-gray-700">
              <div v-if="application.admission_status === 'selected'" class="text-xl font-semibold">
                {{ $t('admittedApplication') }}
              </div>
              <div v-else class="text-xl font-semibold">{{ $t('rejectedApplication') }}</div>
              <div class="text-sm font-medium">{{ application.decision_date | formatDateTime }}</div>
            </div>
          </div>
        </div>
      </div>

      <fw-panel v-if="!checks.isDraft && !checks.isCanceled" :title="$t('statusMainBoardTitle')" class="my-10">
        <div class="flex gap-3 text-gray-500 w-full">
          <template v-for="(status, key) in applicationStatesForPanel">
            <div
              v-if="status.show"
              :key="key"
              :class="{
                'w-2/4 border-primary min-h-28': status.isRunning,
                'w-1/3 border-white min-h-28': !status.isRunning,
                'text-primary': status.isActive || status.isDone,
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 group"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0" />
                </div>
                <div v-else-if="status.isRunning">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold': status.isActive || status.isDone,
                }"
              >
                {{ status.label }}
              </div>
              <div class="flex justify-center items-center font-normal text-xs">
                <div v-if="status.isRunning" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="status.isDone && key !== 'closed'">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="!status.isDone && !status.isActive && status.canChange">
                  {{ $t('phasePending') }}
                </div>
                <div v-else-if="key === 'closed' && status.isDone">
                  {{ $t('phaseClosed') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </fw-panel>

      <fw-panel v-if="canSeeComplaintsPanel" :title="$t('stakeholderHearing')" class="my-5" boxed="sm">
        <PanelApplicationComplaints
          :application="application"
          :procedure-code="procedureCode"
          :can-complain="checks.canComplain"
          :deadline-exceeded="deadline.deadlineExceeded"
          :deadline-to-complaint="deadline.deadlineToComplaint"
          @open-complaint="$emit('open-complaint', $event)"
        />
      </fw-panel>

      <fw-panel-info debug label="Data (raw)" class="my-5">
        <json-viewer :value="{ application, procedure, checks }"></json-viewer>
      </fw-panel-info>
    </div>
  </div>
</template>

<script>
import PanelApplicationComplaints from '@/components/application/PanelApplicationComplaints'
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  components: {
    PanelApplicationComplaints,
  },

  props: {
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },
    savingData: {
      type: Boolean,
    },
  },

  data() {
    return {
      applicationStates: APPLICATION_STATES['scholarship'],
      openedComplaint: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    applicationStatesForPanel() {
      console.log('this.language :>> ', this.language)
      return {
        submitted: {
          label: this.applicationStates.submitted.label[this.language],
          canChange: true,
          isActive: this.application.status === 'submitted',
          isRunning: false,
          isDone: this.application.status !== 'draft',
          show: true,
        },
        running: {
          label: this.applicationStates.running.label[this.language],
          canChange: false,
          isRunning: this.application.status === 'submitted' && this.procedure.public_status === 'ongoing',
          isActive: this.application.status === 'submitted' && this.procedure.public_status === 'ongoing',
          isDone: this.procedure.public_status === 'ended',
          show: this.application.status === 'submitted',
        },
        closed: {
          label: this.applicationStates.closed.label[this.language],
          canChange: false,
          isRunning: false,
          isActive: false,
          isDone: this.procedure.public_status === 'ended',
          show: true,
        },
      }
    },
    procedureCode() {
      return this.$route.params.procedureKey
    },

    canSeeComplaintsPanel() {
      return this.procedure.status === 'ended' || this.application.admission_status == 'rejected'
    },

    deadline() {
      if (this.application.admission_status == 'rejected') {
        return {
          deadlineExceeded: this.procedure.complaints_info.rejected_remaining_time < 0,
          deadlineToComplaint: this.procedure.complaints_info.rejected_end_date,
        }
      } else {
        return {
          deadlineExceeded: this.procedure.complaints_info.end_date_remaining_time < 0,
          deadlineToComplaint: this.procedure.complaints_info.end_date,
        }
      }
    },
  },

  methods: {
    openComplaint(id) {
      this.openedComplaint = id
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "yourApplication": "A sua candidatura",
    "key": "Chave",
    "creationDate": "Data de criação",
    "submittedDate": "Data de submissão",
    "notDefined": "Não definido",
    "applicationStatus": "Estado da candidatura",
    "decision": "Decisão da fase de admissão",
    "admittedApplication": "Candidatura admitida",
    "rejectedApplication": "Candidatura rejeitada",
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Processo terminado",
    "seeProcedureDetails": "Ver detalhes da bolsa",
    "stakeholderHearing": "Audiência de interessados"
  },
  "en": {
    "statusMainBoardTitle": "Status",
    "yourApplication": "Your application",
    "key": "Key",
    "creationDate": "Creation date",
    "submittedDate": "Submission date",
    "notDefined": "Not defined",
    "applicationStatus": "Application status",
    "decision": "Decision at the admission stage",
    "admittedApplication": "Admitted application",
    "rejectedApplication": "Rejected application",
    "phaseFinished": "Finished",
    "phaseOngoing": "Ongoing",
    "phasePending": "Pending",
    "phaseClosed": "Process completed",
    "seeProcedureDetails": "See scholarship details",
    "stakeholderHearing": "Stakeholder hearing"
  }
}
</i18n>
