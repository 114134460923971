<template>
  <div class="relative flex flex-col h-full">
    <div class="px-5 py-4 border-b shadow-sm">
      <fw-panel :title="$t('application')" featured :loading="savingData" after-loading-checked>
        <template v-if="canEdit && checks.isDraft" #toolbar>
          <div class="flex gap-1 items-center">
            <div
              v-if="$v.application.$error"
              class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-semibold"
            >
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('hasErrors') }}</span>
            </div>

            <div v-if="canEdit" class="flex gap-5">
              <fw-button
                :type="canSubmit ? 'light-primary' : 'primary'"
                :disabled="savingData"
                :loading="savingData"
                @click.native="saveApplication"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>
      </fw-panel>
    </div>
    <div class="flex-1 overflow-y-auto h-full form-wrapper">
      <div class="mx-auto max-w-screen-md relative">
        <fw-loading-blur-panel v-if="savingData" />
        <fw-panel
          id="form_section_personal_data"
          :title="$t('personalData')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('personal_data') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check v-if="getStepStatus('personal_data') === 'finished'" class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <div>
              <fw-label>{{ $t('fullName') }}</fw-label>
              <TextInput
                v-model="$v.application.name.$model"
                :disabled="!canEdit"
                :minlength="3"
                :maxlength="255"
                autocomplete="new-password"
                :class="{
                  error: $v.application.name.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.name.$error" error>
                <span v-if="!$v.application.name.required">{{ $t('errors.required.name') }}</span>
                <span v-else>{{ $t('errors.invalid.name') }}</span>
              </fw-tip>
            </div>
            <div class="mt-4">
              <fw-label>{{ $t('email') }}</fw-label>
              <TextInput
                v-model="$v.application.email.$model"
                :disabled="!canEdit"
                type="email"
                :minlength="3"
                :maxlength="255"
                autocomplete="new-password"
                :class="{
                  error: $v.application.email.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.email.$error" error>
                <span v-if="!$v.application.email.required">{{ $t('errors.required.email') }}</span>
                <span v-else>{{ $t('errors.invalid.email') }}</span>
              </fw-tip>
            </div>
            <div class="mt-4">
              <fw-label>{{ $t('address') }}</fw-label>
              <TextInput
                v-model="$v.application.address_line1.$model"
                :disabled="!canEdit"
                :minlength="3"
                :maxlength="255"
                :placeholder="$t('address')"
                :class="{
                  error: $v.application.address_line1.$error,
                }"
              ></TextInput>
              <fw-tip>
                <span v-html="$t('help.address')"></span>
              </fw-tip>
              <fw-tip v-if="$v.application.address_line1.$error" error>
                <span v-if="!$v.application.address_line1.required">{{ $t('errors.required.address') }}</span>
                <span v-else>{{ $t('errors.invalid.address') }}</span>
              </fw-tip>
              <TextInput
                v-model="$v.application.address_line2.$model"
                class="mt-2"
                :disabled="!canEdit"
                :minlength="3"
                :maxlength="255"
                :placeholder="$t('address2')"
                :class="{
                  error: $v.application.address_line2.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.address_line2.$error" error>
                {{ $t('errors.invalid.address') }}
              </fw-tip>
            </div>
            <div class="mt-4 flex gap-4">
              <div>
                <fw-label>{{ $t('postalCode') }}</fw-label>
                <TextInput
                  v-model="$v.application.postal_code.$model"
                  placeholder="XXXX-XXX"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.postal_code.$error,
                  }"
                ></TextInput>
                <fw-tip v-if="$v.application.postal_code.$error" error>
                  <span v-if="!$v.application.postal_code.required">{{ $t('errors.required.postalCode') }}</span>
                  <span v-else>{{ $t('errors.invalid.postalCode') }}</span>
                </fw-tip>
              </div>
              <div>
                <fw-label>{{ $t('locality') }}</fw-label>
                <TextInput
                  v-model="$v.application.locality.$model"
                  :placeholder="$t('locality')"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.locality.$error,
                  }"
                ></TextInput>
                <fw-tip v-if="$v.application.locality.$error" error>
                  <span v-if="!$v.application.locality.required">{{ $t('errors.required.locality') }}</span>
                  <span v-else>{{ $t('errors.invalid.locality') }}</span>
                </fw-tip>
              </div>
            </div>
            <div class="mt-4">
              <fw-label>{{ $t('country') }}</fw-label>
              <CountriesSelect
                :placeholder="$t('country')"
                :disabled="!canEdit"
                :input="application.country"
                @update="countryChanged($event)"
              />
              <fw-tip v-if="$v.application.country.$error" error>
                <span v-if="!$v.application.country.required">{{ $t('errors.required.country') }}</span>
                <span v-else>{{ $t('errors.invalid.country') }}</span>
              </fw-tip>
            </div>
            <div class="mt-4 flex gap-4">
              <div>
                <fw-label>{{ $t('phoneCountry') }}</fw-label>
                <PhoneCountriesSelect
                  :disabled="!canEdit"
                  :placeholder="$t('phoneCountry')"
                  :input="application.phone_country"
                  @update="phoneCountryChanged($event)"
                />
              </div>
              <div>
                <fw-label>{{ $t('phone') }}</fw-label>
                <NumberInput
                  v-model="$v.application.phone_number.$model"
                  :placeholder="$t('phone')"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.phone_number.$error,
                  }"
                ></NumberInput>
                <fw-tip v-if="$v.application.phone_number.$error" error>
                  <span v-if="!$v.application.phone_number.required">{{ $t('errors.required.phoneNumber') }}</span>
                  <span v-else>{{ $t('errors.invalid.phoneNumber') }}</span>
                </fw-tip>
              </div>
            </div>
            <fw-heading size="h4" class="mt-5 text-gray-500">{{ $t('id.label') }}</fw-heading>
            <div class="flex mt-2 gap-4">
              <div>
                <fw-label>{{ $t('id.type.label') }}</fw-label>
                <b-select
                  v-model="$v.application.identity_type.$model"
                  class="fw-select w-full"
                  :disabled="!canEdit"
                  :placeholder="$t('id.type.placeholder')"
                >
                  <option value="cc">{{ $t('id.type.cc') }}</option>
                  <option value="passport">{{ $t('id.type.passport') }}</option>
                </b-select>
                <fw-tip v-if="$v.application.identity_type.$error" error>
                  {{ $t('errors.required.identityType') }}
                </fw-tip>
              </div>
              <div>
                <fw-label>{{ $t('id.number') }}</fw-label>
                <TextInput
                  v-model="$v.application.identity_code.$model"
                  :placeholder="application.identity_type == 'cc' ? 'DDDDDDDDCAAT' : 'XXXXXXXXX'"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.identity_code.$error,
                  }"
                  :minlength="3"
                  :maxlength="50"
                ></TextInput>
                <fw-tip v-if="$v.application.identity_code.$error" error>
                  <span v-if="!$v.application.identity_code.required">{{ $t('errors.required.identityCode') }}</span>
                  <span v-else>{{ $t('errors.invalid.identityCode') }}</span>
                </fw-tip>
              </div>
              <div>
                <fw-label>{{ $t('id.validDate.label') }}</fw-label>
                <b-datepicker
                  v-model="$v.application.identity_expire_date.$model"
                  :disabled="!canEdit"
                  :min-date="minDate"
                  :years-range="[-10, 50]"
                  locale="pt-PT"
                  :placeholder="$t('id.validDate.placeholder')"
                >
                </b-datepicker>
                <fw-tip v-if="$v.application.identity_expire_date.$error" error>
                  <span v-if="!$v.application.identity_expire_date.required">{{
                    $t('errors.required.identityExpireDate')
                  }}</span>
                  <span v-else>{{ $t('errors.invalid.identityExpireDate') }}</span>
                </fw-tip>
              </div>
            </div>
            <div class="mt-4">
              <div class="flex items-center">
                <fw-label>{{ $t('nif') }}</fw-label>
                <div class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold">
                  {{ $t('optional') }}
                </div>
              </div>
              <TextInput
                v-model="$v.application.nif.$model"
                :disabled="!canEdit"
                :placeholder="$t('nif')"
                :class="{
                  error: $v.application.nif.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.nif.$error" error>
                {{ $t('errors.invalid.nif') }}
              </fw-tip>
              <fw-tip><span v-html="$t('help.nif')"/></fw-tip>
            </div>
            <div class="mt-4">
              <div class="flex items-center">
                <fw-label>{{ $t('science_id') }}</fw-label>
                <div class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold">
                  {{ $t('optional') }}
                </div>
              </div>
              <TextInput
                v-model="$v.application.fct_code.$model"
                :placeholder="$t('science_id')"
                :disabled="!canEdit"
                :class="{
                  error: $v.application.fct_code.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.fct_code.$error" error>{{ $t('errors.invalid.scienceId') }}</fw-tip>
            </div>
          </template>
        </fw-panel>

        <fw-panel id="form_section_cv" :title="$t('cv.title')" class="my-5" boxed="lg" custom-class="bg-white">
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('cv') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check v-if="getStepStatus('cv') === 'finished'" class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <p class="mb-3 text-sm" v-html="$t('cv.instructions')"></p>
            <div>
              <div v-if="application.files.cv && application.files.cv.length > 0" class="files mb-1.5">
                <RecordFileEntry
                  v-for="file in application.files.cv"
                  :key="file.key"
                  :can-edit="false"
                  :can-remove="canEdit"
                  :allow-classified="false"
                  :file="file"
                  @remove="removeFile(file, 'cv')"
                  @download="downloadFile(file)"
                />
              </div>
              <Uploader
                v-if="canEdit"
                :label="$t('uploadFiles')"
                :is-docked="true"
                layout="minimal"
                :limit="filesOptions.cv.max"
                reference-id="uploader_cv"
                allowed="pdf"
                :clear-after="true"
                input-id="upload_input_cv"
                :files.sync="cvFiles"
                :size="0"
                :new-file-context="{}"
                file-type="file"
                file-code="cv"
                class="cursor-pointer update-modal-uploader"
                uploader-class="w-full rounded"
                @upload="uploaded($event, 'cv')"
              />
              <fw-tip v-if="$v.application.files.cv.$error" error>
                {{ $t('errors.required.cv') }}
              </fw-tip>
            </div>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_certificate"
          :title="$t('certificates.title')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('certificate') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check v-if="getStepStatus('certificate') === 'finished'" class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <div>
              <fw-label>{{ $t('certificates.level') }}</fw-label>
              <b-select
                v-model="$v.application.habilitation_level.$model"
                class="fw-select w-full"
                :disabled="!canEdit"
              >
                <option value="bachelor">{{ $t('certificates.options.bachelor') }}</option>
                <option value="graduation">{{ $t('certificates.options.graduation') }}</option>
                <option value="master">{{ $t('certificates.options.master') }}</option>
                <option value="phd">{{ $t('certificates.options.phd') }}</option>
                <option value="not_applicable">{{ $t('certificates.options.not_applicable') }}</option>
              </b-select>
              <fw-tip v-if="$v.application.habilitation_level.$error" error>
                <span v-if="!$v.application.habilitation_level.required">{{
                  $t('errors.required.habilitationLevel')
                }}</span>
                <span v-else>{{ $t('errors.invalid.habilitationLevel') }}</span>
              </fw-tip>
            </div>
            <div class="mt-4">
              <fw-label>{{ $t('certificates.scientific_area') }}</fw-label>
              <TextInput
                v-model="$v.application.scientific_area.$model"
                :disabled="!canEdit"
                :placeholder="$t('certificates.scientific_area_placeholder')"
                :class="{
                  error: $v.application.scientific_area.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.application.scientific_area.$error" error>
                <span v-if="!$v.application.scientific_area.required">{{ $t('errors.required.scientificArea') }}</span>
                <span v-else>{{ $t('errors.invalid.scientificArea') }}</span>
              </fw-tip>
            </div>
            <div class="mt-4">
              <fw-label>{{ $t('certificates.copies') }}</fw-label>
              <p class="mb-3 text-sm" v-html="$t('certificates.files')"></p>
              <div
                v-if="application.files.certificate && application.files.certificate.length > 0"
                class="files mb-1.5"
              >
                <RecordFileEntry
                  v-for="file in application.files.certificate"
                  :key="file.key"
                  :can-edit="false"
                  :can-remove="canEdit"
                  :allow-classified="false"
                  :file="file"
                  @remove="removeFile(file, 'certificate')"
                  @download="downloadFile(file)"
                />
              </div>
              <Uploader
                v-if="canEdit"
                :label="$t('uploadFiles')"
                :is-docked="true"
                layout="minimal"
                reference-id="uploader_certificate"
                allowed="pdf"
                :clear-after="true"
                input-id="upload_input_certificate"
                :files.sync="certificateFiles"
                :size="0"
                :new-file-context="{}"
                file-type="file"
                file-code="certificatee"
                class="cursor-pointer update-modal-uploader"
                uploader-class="w-full rounded"
                @upload="uploaded($event, 'certificate')"
              />
              <fw-tip v-if="$v.application.files.certificate.$error" error>
                {{ $t('errors.required.certificate') }}
              </fw-tip>
            </div>
            <fw-tip>
              <span v-html="$t('help.qualifications')"></span>
            </fw-tip>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_motivation"
          :title="$t('letterOfInterest.title')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag>
          </template>
          <template>
            <div>
              <p class="mb-3 text-sm" v-html="$t('letterOfInterest.files')"></p>
              <div
                v-if="application.files.letter_of_interest && application.files.letter_of_interest.length > 0"
                class="files mb-1.5"
              >
                <RecordFileEntry
                  v-for="file in application.files.letter_of_interest"
                  :key="file.key"
                  :can-edit="false"
                  :can-remove="canEdit"
                  :allow-classified="false"
                  :file="file"
                  @remove="removeFile(file, 'letter_of_interest')"
                  @download="downloadFile(file)"
                />
              </div>
              <fw-panel-info v-else-if="!canEdit" clean class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
              <Uploader
                v-if="canEdit"
                :label="$t('uploadFiles')"
                :is-docked="true"
                layout="minimal"
                :limit="filesOptions.letter_of_interest.max"
                reference-id="uploader_letter_of_interest"
                allowed="pdf"
                :clear-after="true"
                input-id="upload_input_letter_of_interest"
                :files.sync="letterOfInterestFiles"
                :size="0"
                :new-file-context="{}"
                file-type="file"
                file-code="letter_of_interest"
                class="cursor-pointer update-modal-uploader"
                uploader-class="w-full rounded"
                @upload="uploaded($event, 'letter_of_interest')"
              />
              <fw-tip v-if="$v.application.files.letter_of_interest.$error" error>{{
                $t('errors.required.letterOfInterest')
              }}</fw-tip>
            </div>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_recommendation"
          :title="$t('recommendationLetter.title')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag>
          </template>
          <template>
            <div>
              <p class="mb-3 text-sm" v-html="$t('recommendationLetter.files')"></p>
              <div
                v-if="application.files.recommendation_letter && application.files.recommendation_letter.length > 0"
                class="files mb-1.5"
              >
                <RecordFileEntry
                  v-for="file in application.files.recommendation_letter"
                  :key="file.key"
                  :can-edit="false"
                  :can-remove="canEdit"
                  :allow-classified="false"
                  :file="file"
                  @remove="removeFile(file, 'recommendation_letter')"
                  @download="downloadFile(file)"
                />
              </div>
              <fw-panel-info v-else-if="!canEdit" clean class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
              <Uploader
                v-if="canEdit"
                :label="$t('uploadFiles')"
                :is-docked="true"
                layout="minimal"
                reference-id="uploader_recommendation_letter"
                allowed="pdf"
                :clear-after="true"
                input-id="upload_input_recommendation_letter"
                :files.sync="recommendationLetterFiles"
                :size="0"
                :new-file-context="{}"
                file-type="file"
                file-code="recommendation_letter"
                class="cursor-pointer update-modal-uploader"
                uploader-class="w-full rounded"
                @upload="uploaded($event, 'recommendation_letter')"
              />
            </div>
          </template>
        </fw-panel>

        <fw-panel id="form_section_other" :title="$t('other.title')" class="my-5" boxed="lg" custom-class="bg-white">
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag>
          </template>
          <template>
            <div>
              <p class="mb-3 text-sm" v-html="$t('other.files')"></p>
              <div v-if="application.files.other && application.files.other.length > 0" class="files mb-1.5">
                <RecordFileEntry
                  v-for="file in application.files.other"
                  :key="file.key"
                  :can-edit="false"
                  :can-remove="canEdit"
                  :allow-classified="false"
                  :file="file"
                  @remove="removeFile(file, 'other')"
                  @download="downloadFile(file)"
                />
              </div>
              <fw-panel-info v-else-if="!canEdit" clean class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
              <Uploader
                v-if="canEdit"
                :label="$t('uploadFiles')"
                :is-docked="true"
                layout="minimal"
                reference-id="uploader_other"
                allowed="all"
                :clear-after="true"
                input-id="upload_input_other"
                :files.sync="otherFiles"
                :size="0"
                :new-file-context="{}"
                file-type="file"
                file-code="other"
                class="cursor-pointer update-modal-uploader"
                uploader-class="w-full rounded"
                @upload="uploaded($event, 'other')"
              />
            </div>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_previous_scholarships"
          :title="$t('previousScholarships.title')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('previous_scholarships') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check
                v-if="getStepStatus('previous_scholarships') === 'finished'"
                class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <div class="mt-2">
              <OnoffOption
                :disabled="!canEdit"
                :options="[$t('no'), $t('yes')]"
                :selected-option="
                  application.has_previous_scholarships === false
                    ? $t('no')
                    : application.has_previous_scholarships === true
                    ? $t('yes')
                    : null
                "
                @input="hasPreviousScholarshipsChanged"
              />
              <span class="ml-2">{{ $t('previousScholarships.label') }}</span>
            </div>
            <PreviousScholarshipsList
              v-if="application.has_previous_scholarships"
              :previous-scholarships="$v.application.other_scholarships.$model"
              :can-edit="canEdit"
              @save="savePreviousScholarship"
              @add="addPreviousScholarship"
              @delete="deletePreviousScholarship"
            />
            <fw-tip v-if="$v.application.other_scholarships.$error" error>
              {{ $t('errors.required.otherScholarships') }}
            </fw-tip>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_course"
          :title="$t('enrolledCourse.title')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('course') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check v-if="getStepStatus('course') === 'finished'" class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <div>
              <fw-label>{{ $t('enrolledCourse.label') }}</fw-label>

              <b-select
                v-model="$v.application.enrolled_course.type.$model"
                class="fw-select w-full"
                :disabled="!canEdit"
              >
                <option value="technical_course">{{ $t('enrolledCourse.options.technical_course') }}</option>
                <option value="other">{{ $t('enrolledCourse.options.other') }}</option>
                <option value="graduate">{{ $t('enrolledCourse.options.graduate') }}</option>
                <option value="integrated_master">{{ $t('enrolledCourse.options.integrated_master') }}</option>
                <option value="master">{{ $t('enrolledCourse.options.master') }}</option>
                <option value="phd">{{ $t('enrolledCourse.options.phd') }}</option>
                <option value="not_applicable">{{ $t('enrolledCourse.options.not_applicable') }}</option>
              </b-select>

              <fw-tip v-if="application.enrolled_course.type == 'other'">
                <span v-html="$t('help.enrolledCourse.other')"> </span>
              </fw-tip>

              <fw-tip v-if="$v.application.enrolled_course.type.$error" error>
                {{ $t('required') }}
              </fw-tip>
            </div>
            <div v-if="application.enrolled_course.type && application.enrolled_course.type != 'not_applicable'">
              <div class="mt-2">
                <fw-label>{{ $t('enrolledCourse.name') }}</fw-label>

                <TextInput
                  v-model="$v.application.enrolled_course.name.$model"
                  :placeholder="$t('enrolledCourse.name')"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.enrolled_course.name.$error,
                  }"
                ></TextInput>

                <fw-tip v-if="$v.application.enrolled_course.name.$error" error>
                  {{ $t('required') }}
                </fw-tip>
              </div>
              <div class="mt-2">
                <fw-label>{{ $t('enrolledCourse.academicYear') }}</fw-label>

                <div class="flex items-center gap-3">
                  <NumberInput
                    v-model="$v.application.enrolled_course.academic_year.start.$model"
                    placeholder="YYYY"
                    :disabled="!canEdit"
                    :class="{
                      error: $v.application.enrolled_course.academic_year.start.$error,
                    }"
                  ></NumberInput>
                  /
                  <NumberInput
                    v-model="$v.application.enrolled_course.academic_year.end.$model"
                    placeholder="YYYY"
                    :disabled="!canEdit"
                    :class="{
                      error: $v.application.enrolled_course.academic_year.end.$error,
                    }"
                  ></NumberInput>
                </div>

                <fw-tip v-if="$v.application.enrolled_course.academic_year.$error" error>
                  <span
                    v-if="
                      !$v.application.enrolled_course.academic_year.start.required ||
                        !$v.application.enrolled_course.academic_year.end.required
                    "
                    >{{ $t('errors.required.academicYear') }}</span
                  >
                  <span v-else>{{ $t('errors.invalid.academicYear') }}</span>
                </fw-tip>
              </div>
              <div class="mt-2">
                <fw-label>{{ $t('enrolledCourse.institution') }}</fw-label>

                <TextInput
                  v-model="$v.application.enrolled_course.institution.$model"
                  :placeholder="$t('enrolledCourse.institution')"
                  :disabled="!canEdit"
                  :class="{
                    error: $v.application.enrolled_course.institution.$error,
                  }"
                ></TextInput>

                <fw-tip v-if="$v.application.enrolled_course.institution.$error" error>
                  {{ $t('required') }}
                </fw-tip>
              </div>
            </div>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_teleconference"
          :title="$t('teleconference')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag>
          </template>
          <template>
            <div class="mt-2">
              <b-switch v-model="application.remote_interview" :disabled="!canEdit">
                {{ $t('acceptTeleconference') }}
              </b-switch>
            </div>
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_additional_info"
          :title="$t('additionalInformation')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag>
          </template>
          <template>
            <fw-label>{{ $t('otherInformation') }}</fw-label>
            <HtmlEditor
              id="user_notes"
              :value="application.user_notes"
              :disabled="!canEdit"
              @input="userNotesChanged"
            />
          </template>
        </fw-panel>

        <fw-panel
          id="form_section_declaration"
          :title="$t('declarationOfHonor')"
          class="my-5"
          boxed="lg"
          custom-class="bg-white"
        >
          <template v-if="canEdit && checks.isDraft" #toolbar>
            <fw-tag :type="getStepStatus('declaration') === 'finished' ? 'xlight' : 'orange'" size="xs"
              >{{ $t('required') }}
              <fw-icon-check v-if="getStepStatus('declaration') === 'finished'" class="w-4 h-4 ml-2 text-primary"
            /></fw-tag>
          </template>
          <template>
            <div class="mt-2">
              <b-switch v-model="$v.application.acceptance_documents_authenticity.$model" :disabled="!canEdit">
                {{ $t('acceptanceDocumentsAuthenticity') }}
              </b-switch>
              <fw-tip v-if="$v.application.acceptance_documents_authenticity.$error" error>
                {{ $t('errors.required.acceptanceDocumentsAuthenticity') }}
              </fw-tip>
            </div>
            <div class="mt-2">
              <b-switch v-model="$v.application.acceptance_requirements.$model" :disabled="!canEdit">
                {{ $t('acceptRequirements') }}
              </b-switch>
              <fw-tip v-if="$v.application.acceptance_requirements.$error" error>
                {{ $t('errors.required.acceptRequirements') }}
              </fw-tip>
            </div>
            <div class="mt-2">
              <b-switch v-model="$v.application.accept_authenticity.$model" :disabled="!canEdit">
                {{ $t('acceptAuthenticity') }}
              </b-switch>
              <fw-tip v-if="$v.application.accept_authenticity.$error" error>
                {{ $t('errors.required.acceptAuthenticity') }}
              </fw-tip>
            </div>
            <div class="mt-2">
              <b-switch v-model="$v.application.accept_notifications.$model" :disabled="!canEdit">
                {{ $t('acceptNotifications') }}
              </b-switch>
              <fw-tip v-if="$v.application.accept_notifications.$error" error>
                {{ $t('errors.required.acceptNotifications') }}
              </fw-tip>
            </div>
            <div class="mt-2">
              <b-switch v-model="$v.application.accept_course_verify.$model" :disabled="!canEdit">
                {{ $t('acceptCourseVerify') }}
              </b-switch>
              <fw-tip v-if="$v.application.accept_course_verify.$error" error>
                {{ $t('errors.required.acceptCourseVerify') }}
              </fw-tip>
              <fw-tip
                ><span
                  v-html="
                    $t('help.acceptCourseVerify', {
                      DGES_url: 'https://www.dges.gov.pt/en/pagina/degree-and-diploma-recognition',
                      SGA_url: 'https://www.uc.pt/academicos/graus/reconhecimentos',
                    })
                  "
                ></span
              ></fw-tip>
            </div>
            <div class="mt-2">
              <fw-label>{{ $t('inCaseOfHandycap') }}</fw-label>
              <b-switch v-model="application.is_handycap" :disabled="!canEdit">
                {{ $t('isHandicap') }}
              </b-switch>
            </div>
          </template>
        </fw-panel>
      </div>
    </div>
  </div>
</template>

<script>
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import CountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/CountriesSelect'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import PreviousScholarshipsList from '@/components/panels/PreviousScholarshipsList'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'

import { required, maxLength, minLength, email, minValue, numeric } from 'vuelidate/lib/validators'

import { postalCode, nifOptional, validCC, academicYear } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'

export default {
  components: {
    TextInput,
    NumberInput,
    CountriesSelect,
    PhoneCountriesSelect,
    Uploader,
    RecordFileEntry,
    PreviousScholarshipsList,
    HtmlEditor,
    OnoffOption,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    canEdit: {
      type: Boolean,
      default: false,
    },

    canSubmit: {
      type: Boolean,
      default: false,
    },

    canOpen: {
      type: Boolean,
      default: false,
    },

    filesOptions: {
      type: Object,
      default: () => {
        return {
          cv: {
            max: 1,
          },
          letter_of_interest: {
            max: 1,
          },
        }
      },
    },

    application: {
      type: Object,
      default: () => {
        return {}
      },
    },

    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    steps: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  validations() {
    return {
      application: {
        // Personal data
        name: { required, min: minLength(3), max: maxLength(255) },
        email: { required, min: minLength(3), max: maxLength(255), email },
        address_line1: { required, min: minLength(3), max: maxLength(255) },
        address_line2: { max: maxLength(255) },
        postal_code: { required, validPostalCode: postalCode(true) },
        locality: { required, min: minLength(3), max: maxLength(255) },
        country: { required },
        phone_number: { required, min: minLength(3), max: maxLength(255) },
        identity_type: { required },
        identity_code: { required, min: minLength(3), max: maxLength(50), validCC },
        identity_expire_date: { required, min: minValue(this.minDate) },
        fct_code: { max: maxLength(255) },
        nif: { nifOptional },

        // Files
        files: {
          cv: { required, max: maxLength(this.filesOptions.cv.max) },
          certificate: { required, min: minLength(1) },
          letter_of_interest: { max: maxLength(this.filesOptions.letter_of_interest.max) },
        },

        // Scholarships
        has_previous_scholarships: { required },
        other_scholarships: {
          min: (value, vm) => vm.has_previous_scholarships === false || value.length > 0,
        },

        // Course
        enrolled_course: {
          // designação do curso, ano letivo e instituição onde se encontra inscrito
          type: { required, min: minLength(3) },
          name: {
            required: (value, vm) => vm.type == 'not_applicable' || Boolean(value),
            min: minLength(3),
            max: maxLength(255),
          },
          academic_year: {
            validYear: academicYear(this.application.enrolled_course.type !== 'not_applicable'),
            start: {
              required: value => this.application.enrolled_course.type == 'not_applicable' || Boolean(value),
              numeric,
            },
            end: {
              required: value => this.application.enrolled_course.type == 'not_applicable' || Boolean(value),
              numeric,
            },
          },
          institution: {
            required: (value, vm) => vm.type == 'not_applicable' || Boolean(value),
            min: minLength(3),
            max: maxLength(255),
          },
        },

        // Habilitations
        habilitation_level: { required, min: minLength(1) },
        scientific_area: { required, min: minLength(1) },

        // Acceptance
        accept_authenticity: { checked: value => value === true },
        acceptance_documents_authenticity: { checked: value => value === true },
        acceptance_requirements: { checked: value => value === true },
        accept_notifications: { checked: value => value === true },
        accept_course_verify: { checked: value => value === true },
      },
    }
  },

  data() {
    return {
      editMode: true,
      cvFiles: [],
      certificateFiles: [],
      letterOfInterestFiles: [],
      recommendationLetterFiles: [],
      otherFiles: [],
      loading: false,
      minDate: new Date(),
      maxYear: new Date().getFullYear(),
    }
  },

  computed: {
    savingData() {
      return this.$store.state.isSaving
    },

    tmpSteps() {
      return [
        {
          text: this.$t('personalData'),
          value: 'personal_data',
          status:
            !this.$v.application.name.$invalid &&
            !this.$v.application.email.$invalid &&
            !this.$v.application.address_line1.$invalid &&
            !this.$v.application.address_line2.$invalid &&
            !this.$v.application.postal_code.$invalid &&
            !this.$v.application.locality.$invalid &&
            !this.$v.application.country.$invalid &&
            !this.$v.application.phone_number.$invalid &&
            !this.$v.application.identity_type.$invalid &&
            !this.$v.application.identity_code.$invalid &&
            !this.$v.application.identity_expire_date.$invalid &&
            !this.$v.application.fct_code.$invalid &&
            !this.$v.application.nif.$invalid
              ? 'finished'
              : 'next',
        },
        {
          text: this.$t('cv.title'),
          value: 'cv',
          status: !this.$v.application.files.cv.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('certificates.title'),
          value: 'certificate',
          status: !this.$v.application.files.certificate.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('letterOfInterest.title'),
          value: 'motivation',
          status: !this.$v.application.files.letter_of_interest.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('recommendationLetters'),
          value: 'recomendation',
          status: 'finished',
        },
        {
          text: this.$t('other.title'),
          value: 'other',
          status: 'finished',
        },
        {
          text: this.$t('declarationOfHonor'),
          value: 'declaration',
          status:
            !this.$v.application.acceptance_documents_authenticity.$invalid &&
            !this.$v.application.acceptance_requirements.$invalid &&
            !this.$v.application.accept_notifications.$invalid &&
            !this.$v.application.accept_course_verify.$invalid &&
            !this.$v.application.accept_authenticity.$invalid
              ? 'finished'
              : 'next',
        },
        {
          text: this.$t('previousScholarships.title'),
          value: 'previous_scholarships',
          status: !this.$v.application.other_scholarships.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('enrolledCourse.title'),
          value: 'course',
          status: !this.$v.application.enrolled_course.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('teleconference'),
          value: 'teleconference',
          status: 'finished',
        },
        {
          text: this.$t('additionalInformation'),
          value: 'additional_info',
          status: 'finished',
        },
      ]
    },
  },

  watch: {
    tmpSteps(newVal) {
      this.$emit('update:steps', newVal)
    },
    '$v.application.$error'(hasError) {
      this.$emit('form-with-errors', hasError)
    },
  },

  created() {
    this.$emit('update:steps', this.tmpSteps)
  },

  methods: {
    getSteps() {
      return this.steps
    },

    getStepStatus(stepKey) {
      const step = this.steps.find(step => step.value === stepKey)
      return step ? step.status : null
    },

    countryChanged(value) {
      console.log('countryChanged', value)
      this.application.country = value
      this.$v.application.country.$touch()
    },

    phoneCountryChanged(value) {
      console.log('phoneCountryChanged', value)
      this.application.phone_country = value
      this.$v.application.phone_country.$touch()
    },

    identityTypeChanged(value) {
      console.log('identityTypeChanged', value)
      this.application.identity_type = value
      this.$v.application.phone_country.$touch()
    },

    userNotesChanged(value) {
      console.log('userNotesChanged', value)
      this.application.user_notes = value
    },

    hasPreviousScholarshipsChanged(value) {
      console.log('hasPreviousScholarshipsChanged', value)
      this.application.has_previous_scholarships = Boolean(value === 1)
    },

    saveApplication() {
      this.$v.application.$touch()
      if (!this.$v.application.$invalid) {
        this.$emit('save', 'update', this.application)
      }
    },

    submitApplication() {
      this.$v.application.$touch()

      if (!this.$v.application.$invalid && this.canSubmit) {
        this.$emit('save', 'submit', this.application)
      }
    },

    openApplication() {
      this.$v.application.$touch()

      if (!this.$v.application.$invalid && this.canSubmit) {
        this.$emit('open', 'reopen', this.application)
      }
    },

    async uploaded(files, fileType) {
      console.log('uploaded files', files, fileType)

      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.$emit('relate-files', fileType, newfiles)
    },

    removeFile(file, fileType) {
      console.log('remove', file, fileType)
      this.$emit('remove-file', file, fileType)
    },

    downloadFile(file) {
      this.$emit('download-file', file)
    },

    addPreviousScholarship(data) {
      this.$emit('add-list-entry', data)
    },

    savePreviousScholarship(data) {
      this.$emit('update-list-entry', data)
    },

    deletePreviousScholarship(data) {
      this.$emit('delete-list-entry', data)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "yes": "Sim",
    "no": "Não",
    "charsLimit": "Limite de {limit} caracteres.",
    "application": "Candidatura",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "save": "Guardar",
    "teleconference": "Teleconferência",
    "required": "Obrigatório",
    "optional": "Opcional",
    "uploadFiles": "Carregar ficheiros",
    "acceptance": "Aceitação",
    "handicap": "Portador de deficiência",
    "additionalInformation": "Informações adicionais",
    "fullName": "Nome completo",
    "birthday": "Data de nascimento",
    "address": "Morada",
    "address2": "Morada (linha 2)",
    "postalCode": "Código Postal",
    "locality": "Localidade",
    "country": "País",
    "phoneCountry": "Indicativo do País",
    "phone": "Telefone",
    "email": "Email",
    "noFiles": "Não existem ficheiros associados a esta secção.",
    "id": {
      "label": "Documentos de identificação",
      "type": {
        "label": "Tipo",
        "placeholder": "Selecione um tipo",
        "cc": "Cartão de Cidadão",
        "passport": "Passaporte"
      },
      "number": "Número",
      "emittedDate": {
        "label": "Data de emissão",
        "placeholder": "Data"
      },
      "validDate": {
        "label": "Data de validade",
        "placeholder": "Data"
      }
    },
    "nif": "NIF (Português)",
    "fct": "Número FCT",
    "science_id": "Ciência ID",
    "personalData": "Dados pessoais",
    "personalDataEdit": "Editar dados pessoais",
    "recommendationLetters": "Carta(s) de recomendação",
    "cv": {
      "title": "Curriculum Vitae",
      "files": "Por favor, adicione no campo seguinte, um <strong>ficheiro PDF</strong> com o seu Curriculum Vitae, <strong>datado e assinado</strong>.",
      "filesNotice": "Por favor, adicione no campo seguinte, um <strong>ficheiro PDF</strong> com o seu Curriculum Vitae, <strong>datado, assinado e organizado de acordo com os critérios previstos no edital/aviso</strong>.",
      "instructions": "Por favor, adicione no campo seguinte, um <strong>ficheiro PDF</strong> com o seu Curriculum Vitae, <strong>datado, assinado e organizado de acordo com os critérios previstos no edital</strong> e em <strong>conformidade</strong> com a <strong>declaração de alargamento</strong> do período considerado para avaliação do seu percurso, se aplicável.",
      "classified": "Documento classificado"
    },
    "classified": "Classificado",
    "letterOfInterest": {
      "title": "Carta de motivação",
      "files": "Por favor, adicione no campo seguinte, um <strong>ficheiro PDF</strong> com a sua Carta de Motivação."
    },
    "recommendationLetter": {
      "title": "Carta(s) de recomendação",
      "files": "Adicione no campo seguinte, <strong>um ou mais ficheiros (PDF)</strong> com as suas Cartas de Recomendação."
    },
    "other": {
      "title": "Ficheiros adicionais",
      "files": "Adicione <strong>um ou mais ficheiros</strong> com cópia de quaisquer outros elementos que considere relevantes."
    },
    "previousScholarships": {
      "title": "Bolsas anteriormente recebidas",
      "label": "Recebeu bolsas anteriormente"
    },
    "enrolledCourse": {
      "title": "Curso inscrito",
      "label": "Identifique o curso em que está inscrito como estudante",
      "name": "Designação do curso",
      "academicYear": "Ano letivo",
      "institution": "Instituição onde se encontra inscrito",
      "options": {
        "technical_course": "Curso técnico superior profissional",
        "other": "Curso não conferente de grau académico integrado no projeto educativo de uma instituição de ensino superior",
        "graduate": "Licenciatura",
        "integrated_master": "Mestrado integrado",
        "master": "Mestrado",
        "phd": "Doutoramento",
        "not_applicable": "Não aplicável (titular de grau de doutor)"
      }
    },
    "otherInformation": "Indique outras informações ou detalhes adicionais relevantes à sua candidatura.",
    "declarationOfHonor": "Declaração de honra",
    "acceptTeleconference": "Caso resida a mais de 500 km de Coimbra, e caso o pretenda, requer a possibilidade de a sua Audição Pública, a existir, ocorrer por teleconferência.",
    "certificates": {
      "title": "Certificados de habilitações",
      "copies": "Cópias dos certificados",
      "scientific_area": "Área Científica",
      "scientific_area_placeholder": "Área Científica da sua formação",
      "copiesDeclaration": "Declaro que a cópia dos certificados de habilitações se encontra no meu processo individual junto da Universidade de Coimbra, pelo que, solicito a dispensa da entrega dos mesmos.",
      "files": "Adicione no campo seguinte, <strong>um ou mais ficheiros PDF</strong> com cópia dos seus certificados de habilitações",
      "level": "Nível habilitacional",
      "options": {
        "bachelor": "Bacharelato",
        "graduation": "Licenciatura",
        "master": "Mestrado",
        "phd": "Doutoramento",
        "not_applicable": "Não aplicável"
      },
      "phdDate": "Data do doutoramento",
      "phdCountry": "Tipo de doutoramento",
      "phdInternational": "Doutoramento internacional",
      "phdInternationalDate": "Data do reconhecimento",
      "phdInternationalProof": "ou cópia do reconhecimento do grau de doutor obtido no estrangeiro."
    },
    "inCaseOfHandycap": "No caso de ser portador de deficiência:",
    "isHandicap": "Declaro, sob compromisso de honra, que sou detentor de deficiência e indico no campo Informações adicionais o respetivo grau de incapacidade, o tipo de deficiência e os meios de comunicação/expressão a utilizar no processo de seleção, nos termos do Decreto -Lei n.º 29/2001, de 3 de fevereiro, bem como anexo o comprovativo da minha condição.",
    "acceptCourseVerify": "Declaro que estou em condições de comprovar, no momento da contratualização da bolsa, a frequência no(s) curso(s) que integram os requisitos de admissão, definidos em edital.",
    "acceptanceDocumentsAuthenticity": "Declaro, sob compromisso de honra, que é autêntica toda a informação e documentação incluídas na candidatura, sem prejuízo da efetiva comprovação, sempre que solicitada.",
    "acceptNotifications": "Declaro que consinto que todas as notificações efetuadas no âmbito do procedimento a que ora me candidato sejam feitas através de correio eletrónico para o endereço indicado na candidatura e através de notificação eletrónica automaticamente gerada pela presente plataforma.",
    "acceptRequirements": "Declaro que reúno todos os requisitos dispostos no aviso de abertura para a contratualização da presente bolsa, incluindo a inscrição em curso conferente/não conferente de grau.",
    "acceptAuthenticity": "Declaro que possuo o grau/a inscrição em grau referido no aviso de abertura, para a admissão, à presente bolsa.",
    "hasErrors": "Existem erros no formulário",
    "errors": {
      "required": {
        "name": "Insira o seu nome",
        "email": "Insira o seu email",
        "address": "Insira a sua morada",
        "postalCode": "Insira o seu código postal",
        "locality": "Insira a localidade em que mora",
        "country": "Indique o seu país",
        "phoneNumber": "Insira o seu  número de telefone",
        "identityType": "Insira um documento de identificação",
        "identityCode": "Insira o número do documento de identificação",
        "identityExpireDate": "Insira a data de validade",
        "nif": "Insira um NIF português",
        "cv": "Insira um ficheiro PDF",
        "habilitationLevel": "Insira o seu nível habilitacional",
        "scientificArea": "Indique a área científica da sua formação",
        "certificate": "Insira um ou mais ficheiros PDF",
        "letterOfInterest": "Insira um ficheiro PDF",
        "acceptanceDocumentsAuthenticity": "A informação que inserir tem de ser autêntica",
        "acceptCourseVerify": "Tem de aceitar as condições",
        "acceptNotifications": "Tem de aceitar as notificações do procedimento",
        "otherScholarships": "Descreva as bolsas recebidas.",
        "acceptRequirements": "Tem de aceitar as condições",
        "acceptAuthenticity": "Tem de aceitar as condições",
        "academicYear": "Insira o ano letivo"
      },
      "invalid": {
        "name": "Nome inválido",
        "email": "Email inválido",
        "address": "Morada inválida",
        "postalCode": "Código postal inválido",
        "locality": "Localidade inválida",
        "country": "País inválido",
        "phoneNumber": "Número de telefone inválido",
        "identityCode": "Número do documento de indentificação inválido",
        "identityExpireDate": "Data inválida",
        "nif": "NIF inválido",
        "scienceId": "Ciência ID inválido",
        "habilitationLevel": "Nível habilitacional inválido",
        "scientificArea": "Área científica inválida",
        "academicYear": "Ano letivo inválido"
      }
    },
    "help": {
      "enrolledCourse": {
        "other": "Consideram-se “Cursos não conferentes de grau académico” os cursos a que se refere a alínea e) do n.º 3 do artigo 4.º do Decreto-Lei n.º 74/2006, na sua redação atual, desde que, de acordo com o previsto na alínea e) do Artigo 3.º do RBI da FCT, desenvolvidos em associação ou cooperação entre a instituição de ensino superior e uma ou várias unidades de I&D. Para mais informações consultar: <a class='font-bold' href='https://www.uc.pt/candidaturas/cncg' target='_blank'>www.uc.pt/candidaturas/cncg</a>"
      },
      "qualifications": "Qualquer pessoa que tenha concluído um curso de ensino superior no estrangeiro, numa instituição de ensino superior reconhecida/acreditada pelas autoridades competentes do respetivo país, tem de requerer, em caso de seleção, o reconhecimento automático/de grau/de nível, nos termos do Decreto-lei nº 66/2018 de 16 de agosto e Portaria n.º 33/2019, de 25 de janeiro, sempre que o respetivo grau seja um requisito para atribuição da bolsa. Este documento, quando obrigatório, terá de ser entregue até à data prevista para contratação, sob pena de não contratação.<br>Poderá encontrar mais informações, em: <a class='font-bold' href='https://www.uc.pt/en/academicos/graus/reconhecimentos' target='_blank'>www.uc.pt/en/academicos/graus/reconhecimentos</a>",
      "nif": "O NIF é um elemento obrigatório à contratação, pelo que, em caso de não possuir um número de identificação fiscal português, V.ª Ex.ª deverá diligenciar, em caso de seleção, a obtenção do mesmo, com a brevidade possível. Poderá consultar mais informações sobre a obtenção de NIF (número de identificação fiscal) em <a class='font-bold' href='https://portaldascomunidades.mne.gov.pt/pt/atendimento/no-estrangeiro/atribuicao-de-numero-de-identificacao-fiscal-nif' target='_blank'>www.portaldascomunidades.mne.gov.pt/pt/atendimento/no-estrangeiro/atribuicao-de-numero-de-identificacao-fiscal-nif</a>",
      "address": "No caso de não ter morada portuguesa, obrigatória à data da contratação, por favor, completar o campo com a morada do centro de investigação da Universidade de Coimbra onde se irá realizar a presente bolsa. Poderá encontrar mais informações em <a class='font-bold' href='https://www.uc.pt/welcomecentre' target='_blank'>www.uc.pt/welcomecentre</a>",
      "acceptCourseVerify": "Poderá consultar informação ou requerer o reconhecimento de diplomas e/ou graus superiores, na <a class='font-bold' href='{DGES_url}' target='_blank'>DGES</a> ou <a class='font-bold' href='SGA_url' target='_blank'>SGA - Informações e Serviços Académicos</a>."
    }
  },
  "en": {
    "yes": "Yes",
    "no": "No",
    "charsLimit": "Limit of {limit} characters.",
    "application": "Application",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "save": "Save",
    "teleconference": "Remote interview",
    "required": "Required",
    "optional": "Optional",
    "uploadFiles": "Upload files",
    "acceptance": "Acceptance",
    "handicap": "Candidates with Disabilities",
    "additionalInformation": "Aditional informations",
    "fullName": "Full Name",
    "birthday": "Birthdate",
    "address": "Address (line 1)",
    "address2": "Address (line 2)",
    "postalCode": "Postal Code",
    "locality": "City",
    "country": "Country",
    "phoneCountry": "Country calling code",
    "phone": "Phone",
    "email": "E-mail",
    "noFiles": "There are no files associated with this section.",
    "id": {
      "label": "Identification documents",
      "type": {
        "label": "Type",
        "placeholder": "Select a type",
        "cc": "Citizen ID card",
        "passport": "Passport"
      },
      "number": "Number",
      "emittedDate": {
        "label": "Emission date",
        "placeholder": "Date"
      },
      "validDate": {
        "label": "Validity date",
        "placeholder": "Date"
      }
    },
    "nif": "VAT Number (Portuguese)",
    "fct": "FCT Number",
    "science_id": "Ciência ID",
    "personalData": "Personal details",
    "personalDataEdit": "Update personal data",
    "cv": {
      "title": "Curriculum Vitae",
      "files": "Please add a <strong>PDF file</strong> of your CV <strong>signed and dated</strong>.",
      "filesNotice": "Add your Curriculum Vitae <strong>(a PDF file)</strong> duly <strong>dated and signed and structured in order to respond to the criteria mentioned on the public notice.</strong>.",
      "instructions": "Please add in the following field a <strong>PDF file</strong> with your Curriculum Vitae, <strong>dated, signed and organized according to the criteria set out in the notice</strong> and in <strong>conformity </strong> with the <strong>declaration of extension</strong> of the period considered for evaluation of its course, if applicable.",
      "classified": "Classified document"
    },
    "classified": "Classified",
    "letterOfInterest": {
      "title": "Motivation letter",
      "files": "Please add in the following field a <strong>PDF file</strong> with your Motivation letter."
    },
    "recommendationLetter": {
      "title": "Recommendation Letters",
      "files": "Please add in the following field <strong>one or more files (PDF)</strong> with a copy of your Recommendation Letters."
    },
    "other": {
      "title": "Aditional files",
      "files": "Please add in the following field <strong>one or more files (PDF)</strong> as proof of other relevant information."
    },
    "previousScholarships": {
      "title": "Previous scholarships",
      "label": "I have received a scholarship before"
    },
    "enrolledCourse": {
      "title": "Enrolled course",
      "label": "Identify the course you are enrolled in as a student",
      "name": "Course name",
      "academicYear": "Academic year",
      "institution": "Institution",
      "options": {
        "technical_course": "Professional higher technical course",
        "other": "Non-degree course integrated in the educational project of a higher education institution",
        "graduate": "Bachelor's Degree",
        "integrated_master": "Integrated Master",
        "master": "Master",
        "phd": "PHD",
        "not_applicable": "Not applicable (doctoral degree holder)"
      }
    },
    "declarationOfHonor": "Declaration of Honor",
    "inCaseOfHandycap": "In case you have a disability:",
    "acceptCourseVerify": "I declare that I am able to prove, at the moment of the contractualization of the scholarship, the attendance in the course(s) that integrate the admission requirements, defined in the announcement.",
    "certificates": {
      "title": "Qualification certificates",
      "copies": "Copy of qualification certificates",
      "copiesDeclaration": "I declare that I am exempt from the qualification certificate submission because it is on my personal file at University of Coimbra",
      "files": "Add one or more <strong>PDF files</strong> with a copy of your qualification certificates",
      "level": "Level of Qualification",
      "scientific_area": "Scientific Area",
      "scientific_area_placeholder": "Scientific area of your education",
      "options": {
        "bachelor": "Bachelor's Degree",
        "graduation": "Graduation",
        "master": "Master",
        "phd": "PHD",
        "not_applicable": "Not applicable"
      },
      "phdDate": "Date of PhD",
      "phdCountry": "Nacional/international PhD",
      "phdInternational": "International PhD",
      "phdInternationalDate": "Recognition date",
      "phdInternationalProof": "or copy of the recognition of the doctoral degree obtained abroad."
    },
    "otherInformation": "Please provide other information relevant to your application.",
    "acceptTeleconference": "As I officially reside at more than 500 km from Coimbra, I request that the interview, if applicable, to be held by teleconference.",
    "recommendationLetters": "Recommendation Letters",
    "isHandicap": "I swear that I have a disability and I indicate in the Additional information field the degree of my disability, the type of disability and the means of communication to be used in the selection process, in accordance with Decree-Law no. 29/2001 of 3rd February. I also attach proof of my disability.",
    "acceptanceDocumentsAuthenticity": "I swear that all the information and documents provided in the application are authentic, without prejudice of making proof upon request",
    "acceptNotifications": "I accept all notifications made under the procedure to which I hereby apply shall be made by electronic mail to the address indicated in the application and by electronic notification automatically generated by this platform.",
    "acceptRequirements": "I declare that I meet all the requirements set out in the opening notice for the contracting of this scholarship, including enrollment in a degree granting/non-degree granting course.",
    "acceptAuthenticity": "I declare that I have the degree/registration in the degree mentioned in the opening notice, for admission, to this scholarship.",
    "hasErrors": "There are errors in the form",
    "errors": {
      "required": {
        "name": "Name is required",
        "email": "Email is required",
        "address": "Address is required",
        "postalCode": "Postal code is required",
        "locality": "Enter your locality",
        "country": "Select your country",
        "phoneNumber": "Enter your phone number",
        "identityType": "Insert an identification document",
        "identityCode": "Enter your ID document number",
        "identityExpireDate": "Enter the expiry date",
        "nif": "Enter the VAT number",
        "cv": "Add one PDF file",
        "habilitationLevel": "Enter your qualification level",
        "scientificArea": "Enter the scientific area of your training",
        "certificate": "Add one or more PDF files",
        "letterOfInterest": "Add one PDF file",
        "acceptanceDocumentsAuthenticity": "The information you enter must be authentic",
        "acceptNotifications": "You must accept the procedure notifications",
        "acceptCourseVerify": "You must accept the conditions",
        "otherScholarships": "Enter your previous scholarships",
        "acceptRequirements": "You must accept the conditions",
        "acceptAuthenticity": "You must accept the conditions",
        "academicYear": "Enter the academic year"
      },
      "invalid": {
        "name": "Invalid name",
        "email": "Invalid email",
        "address": "Invalid address",
        "postalCode": "Invalid postal code",
        "locality": "Invalid locality",
        "country": "Invalid country",
        "phoneNumber": "Invalid phone number",
        "identityCode": "Invalid ID document number",
        "identityExpireDate": "Invalid date",
        "nif": "VAT",
        "scienceId": "Science ID",
        "habilitationLevel": "Invalid qualification level",
        "scientificArea": "Invalid scientific area",
        "academicYear": "Invalid academic year"
      }
    },
    "help": {
      "enrolledCourse": {
        "other": "'Courses that do not award an academic degree' are considered to be the courses referred to in paragraph e) of no. 3 of article 4 of Decree-Law No. 74/2006, in its current wording, provided that, in accordance with paragraph e) of Article 3 of the FCT RBI, developed in association or cooperation between the higher education institution and one or more R&D units. For more information consult <a class='font-bold' href='https://www.uc.pt/candidaturas/cncg' target='_blank'>www.uc.pt/candidaturas/cncg</a>"
      },
      "qualifications": "Any person who has completed a higher education course abroad, in a higher education institution recognized/accredited by the competent authorities of the respective country, must request, in case of selection, automatic/degree/level recognition, under the terms of Decree-Law No. 66/2018 of August 16th and Ordinance No. 33/2019 of January 25th, whenever the respective degree is a requirement for awarding the scholarship. This document, when required, must be delivered by the date set for hiring, under penalty of non-hiring.<br>You can find more information at: <a class='font-bold' href='https://www.uc.pt/en/academicos/graus/reconhecimentos' target='_blank'>www.uc.pt/en/academicos/graus/reconhecimentos</a>",
      "nif": "The NIF is a mandatory element for hiring, so, in case you do not have a Portuguese tax identification number, Your Excellency should endeavor, in case of selection, to obtain it, as soon as possible. You can find more information about obtaining a VAT (tax identification number) at <a class='font-bold' href='https://portaldascomunidades.mne.gov.pt/pt/atendimento/no-estrangeiro/atribuicao-de-numero-de-identificacao-fiscal-nif' target='_blank'>www.portaldascomunidades.mne.gov.pt/pt/atendimento/no-estrangeiro/atribuicao-de-numero-de-identificacao-fiscal-nif</a>",
      "address": "If you do not have a Portuguese address, which is mandatory at the time of hiring, please complete the field with the address of the research center at the University of Coimbra where this scholarship will take place. You can find more information at <a class='font-bold' href='https://www.uc.pt/welcomecentre' target='_blank'>www.uc.pt/welcomecentre</a>",
      "acceptCourseVerify": "You can consult information or request the recognition of diplomas and/or higher degrees, at <a class='font-bold' href='{DGES_url}'>DGES</a> or <a class='font-bold' href='SGA_url' target='_blank'>SGA - Informações e Serviços Académicos</a>."
    }
  }
}
</i18n>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}
.procedure .textarea:focus,
.procedure .input:focus,
.procedure .taginput .taginput-container.is-focusable:focus,
.procedure .datepicker .dropdown .input:focus,
.procedure .datepicker .dropdown-trigger .input[readonly]:focus,
.procedure button:focus,
.procedure select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.input-r .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-trigger .control.has-icons-left .input {
  padding-left: 2.5em;
}

.datepicker .dropdown .input,
.datepicker .dropdown-trigger .input[readonly] {
  background-color: #f5f5f5;
}

.textarea,
.input,
.taginput .taginput-container.is-focusable {
  min-height: 35px;
  background-color: #f5f5f5;
}

.datepicker .dropdown .input::placeholder,
.datepicker .dropdown-trigger .input[readonly]::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.textarea::placeholder,
.input::placeholder,
.taginput .taginput-container.is-focusable::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.b-numberinput .control .button {
  height: 38px;
  width: 38px;
}

input::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

input::-ms-input-placeholder {
  color: rgba(108, 105, 105, 0.8);
}
.select:not(.is-multiple) {
  height: 2.75rem;
}
.select select {
  height: 2.75rem;
}
.datepicker-header .pagination-previous {
  width: 3rem;
  height: 2.75rem;
}
.datepicker-header .pagination-next {
  width: 3rem;
  height: 2.75rem;
}

.fw-select .select {
  @apply rounded shadow w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.multiselect {
  @apply rounded shadow border border-gray-200;
}

.multiselect .multiselect__select:before {
  color: #999;
  border-color: #999 transparent transparent;
  border-radius: 2px;
}
</style>
